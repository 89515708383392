import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCheck, shXmark } from '@beyondtrust/shield-icons';

// Data Sources
import {
	dropDownStylesDefaultTheme,
	dropDownStylesDarkTheme,
	dropDownStylesTypography,
	dropDownStylesStructure,
} from './drop-down-styles';

@Component({
	selector: 'app-drop-down',
	templateUrl: './drop-down.component.html',
	styleUrls: ['./drop-down.component.less'],
})
export class DropDownComponent {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefault = dropDownStylesDefaultTheme;
	public stylesDark = dropDownStylesDarkTheme;
	public stylesTypography = dropDownStylesTypography;
	public stylesStructure = dropDownStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Select',
					value: 'select',
				},
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Disable',
					value: 'disable',
				},
				{
					name: 'Typehead (keypress)',
					value: 'typehead',
				},
				{
					name: 'Error',
					value: 'error',
				},
				{
					name: 'Help text',
					value: 'help',
				},
				{
					name: 'Tooltip',
					value: 'tooltip',
				},
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Empty state',
					value: 'empty',
				},
				{
					name: 'Instructional text',
					value: 'instructional',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Default',
					value: 'default',
				},
				{
					name: 'Grouped',
					value: 'grouped',
				},
				{
					name: 'Supporting content',
					value: 'support',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public dropDownHTML = `Code Example Content (found at bottom of .ts file)`;
}
