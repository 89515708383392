// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const barChartStylesDefaultTheme = [
	{
		name: 'Bar Color',
		property: 'background-color',
		value: '6699FF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FC814C',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '99F99F',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'CC99FF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FFD35B',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FF551E',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '99CCCC',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Grid Lines',
		property: 'border-color',
		value: 'E7E9E9',
		isColorTile: true,
	},
];

export const barChartStylesDefaultThemeSeverity = [
	{
		name: 'Bar Color - Critical',
		property: 'background-color',
		value: '783A3B',
		isColorTile: true,
	},
	{
		name: 'Bar Color - High',
		property: 'background-color',
		value: 'C67839',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Moderate',
		property: 'background-color',
		value: 'C6B539',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Low',
		property: 'background-color',
		value: '3992C6',
		isColorTile: true,
	},
];

export const barChartStylesDefaultThemeStatus = [
	{
		name: 'Bar Color - Success',
		property: 'background-color',
		value: '006E18',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Warning',
		property: 'background-color',
		value: 'FFE900',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Error',
		property: 'background-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Informational',
		property: 'background-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const barChartStylesDarkTheme = [
	{
		name: 'Bar Color (40% opacity)',
		property: 'background-color',
		value: '6699FF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FC814C',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '99F99F',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'CC99FF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FFD35B',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: 'FF551E',
		isColorTile: true,
	},
	{
		name: '',
		property: 'background-color',
		value: '99CCCC',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: '1px border that matches the fill color (but solid)',
		isColorTile: false,
	},
	{
		name: 'Grid Lines',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
];

export const barChartStylesDarkThemeSeverity = [
	{
		name: 'Bar Color - Critical',
		property: 'background-color',
		value: 'D6B9FF',
		isColorTile: true,
	},
	{
		name: 'Bar Color - High',
		property: 'background-color',
		value: 'F39681',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Moderate',
		property: 'background-color',
		value: 'F3C781',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Low',
		property: 'background-color',
		value: '71CFE6',
		isColorTile: true,
	},
];

export const barChartStylesDarkThemeStatus = [
	{
		name: 'Bar Color - Success',
		property: 'background-color',
		value: '7FC5A2',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Warning',
		property: 'background-color',
		value: 'F6C664',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Error',
		property: 'background-color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Bar Color - Informational',
		property: 'background-color',
		value: '6699CC',
		isColorTile: true,
	},
];

export const barChartStylesTypography = [
	{
		name: 'Axis Label Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'Chart Legend Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'regular / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const barChartStylesStructure = [
	{
		name: 'Bar Border',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
];
