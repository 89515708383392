// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: wizardStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const wizardStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const wizardStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const wizardStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const wizardStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const wizardStylesDefaultThemeOld = [];

export const wizardStylesDarkThemeOld = [];

export const wizardStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Header Text',
		property: 'font-size',
		value: '20px',
		isColorTile: false,
	},
	{
		name: 'Header Text (H1)',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
];

export const wizardStylesStructureOld = [
	{
		name: 'Left Panel',
		property: 'width',
		value: '33%',
		isColorTile: false,
	},
	{
		name: 'Right Panel',
		property: 'width',
		value: '66%',
		isColorTile: false,
	},
	{
		name: 'Progress Container',
		property: 'margin-top',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Progress Indicator',
		property: 'width',
		value: 'calc(100% - 30px)',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Percentage Container',
		property: 'width',
		value: '30px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Button Icon',
		property: 'padding-left',
		value: '12px',
		isColorTile: false,
	},
];
