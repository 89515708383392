<ul class="page-nav-list">
	<li class="page-nav-list-title">Content</li>
	<li *ngFor="let item of data; index as i" [class.page-nav-sub-list-container]="item.children.length !== 0">
		<span [class.visible]="currVisible === item.value" class="page-nav-list-item"
			[class.page-nav-sub-list-title]="item.children.length !== 0" [class.empty]="item.empty"
			(click)="onAnchorClick(item.value)">
			{{item.name}}
		</span>

		<ul *ngIf="item.children.length !== 0" class="page-nav-sub-list">
			<li class="page-nav-sub-list-item" *ngFor="let subItem of item.children; index as j"
				[class.visible]="currVisible === item.value + '-' + subItem.value"
				(click)="onAnchorClick(item.value + '-' + subItem.value)">
				{{subItem.name}}
			</li>
		</ul>
	</li>
</ul>