import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCheck, shXmark } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-colors',
	templateUrl: './colors.component.html',
	styleUrls: ['./colors.component.less'],
})
export class ColorsComponent {
	public currVisible = 'overview';

	public doThisIcon = shCheck;
	public notThisIcon = shXmark;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'First section',
			value: 'first',
			empty: false,
			children: [
				{
					name: 'A',
					value: 'A',
				},
				{
					name: 'B',
					value: 'B',
				},
			],
		},
		{
			name: 'Second section',
			value: 'second',
			empty: false,
			children: [],
		},
		{
			name: 'Third section',
			value: 'third',
			empty: false,
			children: [
				{
					name: 'A',
					value: 'A',
				},
				{
					name: 'B',
					value: 'B',
				},
				{
					name: 'C',
					value: 'C',
				},
				{
					name: 'D',
					value: 'D',
				},
				{
					name: 'E',
					value: 'E',
				},
			],
		},
		{
			name: 'Resources',
			value: 'resources',
			empty: false,
			children: [],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
