// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const severityStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Empty Dot',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Critical Dot',
		property: 'background-color',
		value: '783A3B',
		isColorTile: true,
	},
	{
		name: 'High Dot',
		property: 'background-color',
		value: 'C67839',
		isColorTile: true,
	},
	{
		name: 'Moderate Dot',
		property: 'background-color',
		value: 'C6B539',
		isColorTile: true,
	},
	{
		name: 'Low Dot',
		property: 'background-color',
		value: '3992C6',
		isColorTile: true,
	},
];

export const severityStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Empty Dot',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Critical Dot',
		property: 'background-color',
		value: 'D6B9FF',
		isColorTile: true,
	},
	{
		name: 'High Dot',
		property: 'background-color',
		value: 'F39681',
		isColorTile: true,
	},
	{
		name: 'Moderate Dot',
		property: 'background-color',
		value: 'F3C781',
		isColorTile: true,
	},
	{
		name: 'Low Dot',
		property: 'background-color',
		value: '71CFE6',
		isColorTile: true,
	},
];

export const severityStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Text - Number (count variation)',
		property: 'font-size',
		value: '36px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '36px',
		isColorTile: false,
	},
	{
		name: 'Text - Body (count variation)',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const severityStylesStructure = [
	{
		name: 'Text',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Dot',
		property: 'border-width',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Text - Number (count variation)',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
];
