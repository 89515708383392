import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCheck, shXmark } from '@beyondtrust/shield-icons';

// Data Sources
import {
	treeBrowserStylesDefaultTheme,
	treeBrowserStylesDarkTheme,
	treeBrowserStylesTypography,
	treeBrowserStylesStructure,
} from './tree-browser-styles';

@Component({
	selector: 'app-tree-browser',
	templateUrl: './tree-browser.component.html',
	styleUrls: ['./tree-browser.component.less'],
})
export class TreeBrowserComponent {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefault = treeBrowserStylesDefaultTheme;
	public stylesDark = treeBrowserStylesDarkTheme;
	public stylesTypography = treeBrowserStylesTypography;
	public stylesStructure = treeBrowserStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'A',
					value: 'A',
				},
				{
					name: 'B',
					value: 'B',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'A',
					value: 'A',
				},
				{
					name: 'B',
					value: 'B',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'A',
					value: 'A',
				},
				{
					name: 'B',
					value: 'B',
				},
				{
					name: 'C',
					value: 'C',
				},
				{
					name: 'D',
					value: 'D',
				},
				{
					name: 'E',
					value: 'E',
				},
			],
		},
		{
			name: 'Placeholder',
			value: 'placeholder',
			empty: false,
			children: [],
		},
		{
			name: 'Resources',
			value: 'resources',
			empty: false,
			children: [],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public treeBrowserHTML = `Code Example Content (found at bottom of .ts file)`;
}
