// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const datePickerStylesDefaultTheme = [
	{
		name: 'Background',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true
	},
	{
		name: 'Month List Text',
		property: 'color',
		value: '253746',
		isColorTile: true
	},
	{
		name: 'Month List Text:hover',
		property: 'color',
		value: '923100',
		isColorTile: true
	},
	{
		name: 'Month List Background',
		property: 'background-color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: 'Selected Month Background',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true
	},
	{
		name: 'Selected Day Background',
		property: 'background-color',
		value: '066C84',
		isColorTile: true
	},
	{
		name: 'Selected Day Text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true
	},
	{
		name: '"Today" Day Number Text',
		property: 'color',
		value: '923100',
		isColorTile: true
	},
	{
		name: 'Day Number Text',
		property: 'color',
		value: '253746',
		isColorTile: true
	},
	{
		name: 'Day Number Text:hover',
		property: 'color',
		value: '253746',
		isColorTile: true
	},
	{
		name: '',
		property: 'background-color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: 'Day Name Text',
		property: 'color',
		value: '7C878E',
		isColorTile: true
	},
	{
		name: 'Scrolling calendar month name',
		property: 'color',
		value: '7C878E',
		isColorTile: true
	},
	{
		name: 'Label text',
		property: 'color',
		value: '253746',
		isColorTile: true
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: '253746',
		isColorTile: true
	}
];

export const datePickerStylesDefaultPrimaryTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '007D9A',
		isColorTile: true
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'C14100',
		isColorTile: true
	}
];

export const datePickerStylesDefaultSecondaryTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '425563',
		isColorTile: true
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'C14100',
		isColorTile: true
	}
];

export const datePickerStylesDarkTheme = [
	{
		name: 'Background',
		property: 'background-color',
		value: '081826',
		isColorTile: true
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true
	},
	{
		name: 'Month List Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: 'Month List Text:hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true
	},
	{
		name: 'Month List Background',
		property: 'background-color',
		value: '253746',
		isColorTile: true
	},
	{
		name: 'Selected Month Background',
		property: 'background-color',
		value: '081826',
		isColorTile: true
	},
	{
		name: 'Selected Day Background',
		property: 'background-color',
		value: '125667',
		isColorTile: true
	},
	{
		name: 'Selected Day Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: '"Today" Day Number Text',
		property: 'color',
		value: 'FF8040',
		isColorTile: true
	},
	{
		name: 'Day Number Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: 'Day Number Text:hover',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true
	},
	{
		name: '',
		property: 'background-color',
		value: '253746',
		isColorTile: true
	},
	{
		name: 'Day Name Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true
	},
	{
		name: 'Scrolling calendar month name',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true
	},
	{
		name: 'Label text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true
	},
	{
		name: 'Instructional text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true
	}
];

export const datePickerStylesDarkPrimaryTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'FF8040',
		isColorTile: true
	}
];

export const datePickerStylesDarkSecondaryTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true
	},
	{
		name: 'Text: focus / active',
		property: 'color',
		value: 'FF8040',
		isColorTile: true
	}
];

export const datePickerStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false
	},
	{
		name: 'Text (Jan in month list)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false
	},
	{
		name: 'Text (scrolling calendar month name)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false
	}
];

export const datePickerStylesStructure = [
	{
		name: 'Container',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: 'Selected Day',
		property: 'border-radius',
		value: '50%',
		isColorTile: false
	}
];
export const datePickerStylesTextInputStructure = [
	{
		name: 'Container',
		property: 'width',
		value: 'initial (approx. 210px)',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '30px',
		isColorTile: false
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false
	},
	{
		name: 'Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	}
];

export const datePickerStylesButtonTextStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: '90px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '36px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: 'unset',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Container Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];
