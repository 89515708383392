export const colorTileData = [
	{
		name: 'White',
		hex: '#FFFFFF',
		rgb: 'rgb(255, 255, 255)',
		hsl: 'hsl(0, 0%, 100%)',
		token: '@color-white',
		type: 'light',
	},
	{
		name: 'Black',
		hex: '#000000',
		rgb: 'rgb(0, 0, 0)',
		hsl: 'hsl(0, 0%, 0%)',
		token: '@color-black',
		type: 'dark',
	},
	{
		name: '--',
		hex: '#EEEEEE',
		rgb: 'rgb(238, 238, 238)',
		hsl: 'hsl(0, 0%, 93.3%)',
		token: '@color-base---',
		type: 'light',
	},
	{
		name: 'Light Ash',
		hex: '#E7E9E9',
		rgb: 'rgb(231, 233, 233)',
		hsl: 'hsl(180, 4.3%, 91%)',
		token: '@color-light-ash',
		type: 'light',
	},
	{
		name: 'Ash',
		hex: '#D0D3D4',
		rgb: 'rgb(208, 211, 212)',
		hsl: 'hsl(195, 4.4%, 82.4%)',
		token: '@color-ash',
		type: 'light',
	},
	{
		name: 'Slate',
		hex: '#425563',
		rgb: 'rgb(66, 85, 99)',
		hsl: 'hsl(205.5, 20%, 32.4%)',
		token: '@color-slate',
		type: 'dark',
	},
	{
		name: 'Steel',
		hex: '#7C878E',
		rgb: 'rgb(124, 135, 142)',
		hsl: 'hsl(203, 7.4%, 52.2%)',
		token: '@color-steel',
		type: 'dark',
	},
	{
		name: 'Chart 1',
		hex: '#6699FF',
		rgb: 'rgb(102, 153, 255)',
		hsl: 'hsl(220, 100%, 70%)',
		token: '@color-chart-01',
		type: 'dark',
	},
	{
		name: 'Chart 2',
		hex: '#FC814C',
		rgb: 'rgb(252, 129, 76)',
		hsl: 'hsl(18, 96.7%, 64.3%)',
		token: '@color-chart-02',
		type: 'light',
	},
	{
		name: 'Chart 3',
		hex: '#99F99F',
		rgb: 'rgb(153, 249, 159)',
		hsl: 'hsl(123.8, 88.9%, 78.8%)',
		token: '@color-chart-03',
		type: 'light',
	},
	{
		name: 'Chart 4',
		hex: '#CC99FF',
		rgb: 'rgb(204, 153, 255)',
		hsl: 'hsl(270, 100%, 80%)',
		token: '@color-chart-04',
		type: 'light',
	},
	{
		name: 'Chart 5',
		hex: '#FFD35B',
		rgb: 'rgb(255, 211, 91)',
		hsl: 'hsl(40, 100%, 67.8%)',
		token: '@color-chart-05',
		type: 'light',
	},
	{
		name: 'Chart 6',
		hex: '#FF551E',
		rgb: 'rgb(255, 85, 30)',
		hsl: 'hsl(15, 100%, 55.9%)',
		token: '@color-chart-06',
		type: 'dark',
	},
	{
		name: 'Chart 7',
		hex: '#99CCCC',
		rgb: 'rgb(153, 204, 204)',
		hsl: 'hsl(180, 33.3%, 70%)',
		token: '@color-chart-07',
		type: 'light',
	},

	{
		name: 'Informational',
		hex: '#1966B3',
		rgb: 'rgb(25, 102, 179)',
		hsl: 'hsl(210, 75.5%, 40.0%)',
		token: '@color-base-status-informational',
		type: 'dark',
	},
	{
		name: 'Orange 50',
		hex: '#FFE6D9',
		rgb: 'rgb(255, 230, 217)',
		hsl: 'hsl(21, 100%, 92.5%)',
		token: '@color-orange-50',
		type: 'light',
	},
	{
		name: 'Orange 100',
		hex: '#FFD4BF',
		rgb: 'rgb(255, 212, 191)',
		hsl: 'hsl(20, 100%, 87.5%)',
		token: '@color-orange-100',
		type: 'light',
	},
	{
		name: 'Orange 200',
		hex: '#FFBB99',
		rgb: 'rgb(255, 187, 153)',
		hsl: 'hsl(20, 100%, 80%)',
		token: '@color-orange-200',
		type: 'light',
	},
	{
		name: 'Orange 250',
		hex: '#F3C781',
		rgb: 'rgb(243, 199, 129)',
		hsl: 'hsl(37, 82.6%, 72.9%)',
		token: '@color-orange-250',
		type: 'light',
	},
	{
		name: 'Orange 275',
		hex: '#F6C664',
		rgb: 'rgb(246, 198, 100)',
		hsl: 'hsl(40.3, 89%, 67.8%)',
		token: '@color-orange-275',
		type: 'light',
	},
	{
		name: 'Orange 300',
		hex: '#FF9966',
		rgb: 'rgb(255, 153, 102)',
		hsl: 'hsl(20, 100%, 70%)',
		token: '@color-orange-300',
		type: 'light',
	},
	{
		name: 'Orange 350',
		hex: '#F39681',
		rgb: 'rgb(243, 150, 129)',
		hsl: 'hsl(11, 82.6%, 72.9%)',
		token: '@color-orange-350',
		type: 'light',
	},
	{
		name: 'Orange 375',
		hex: '#FF9C00',
		rgb: 'rgb(255, 156, 0)',
		hsl: 'hsl(36.7, 100%, 50%)',
		token: '@color-orange-375',
		type: 'dark',
	},
	{
		name: 'Orange 400',
		hex: '#FF8040',
		rgb: 'rgb(255, 128, 64)',
		hsl: 'hsl(20.1, 100%, 62.5%)',
		token: '@color-orange-400',
		type: 'light',
	},
	{
		name: 'Orange 450',
		hex: '#C67839',
		rgb: 'rgb(198, 120, 57)',
		hsl: 'hsl(27, 55.3%, 50%)',
		token: '@color-orange-450',
		type: 'dark',
	},
	{
		name: 'Orange 500',
		hex: '#FF5500',
		rgb: 'rgb(255, 85, 0)',
		hsl: 'hsl(20, 100%, 50%)',
		token: '@color-orange-500',
		type: 'dark',
	},
	{
		name: 'Orange 700',
		hex: '#C14100',
		rgb: 'rgb(193, 65, 0)',
		hsl: 'hsl(20.2, 100%, 37.8%)',
		token: '@color-orange-700',
		type: 'dark',
	},
	{
		name: 'Orange 800',
		hex: '#A23700',
		rgb: 'rgb(162, 55, 0)',
		hsl: 'hsl(20.4, 100%, 31.8%)',
		token: '@color-orange-800',
		type: 'dark',
	},
	{
		name: 'Orange 900',
		hex: '#923100',
		rgb: 'rgb(146, 49, 0)',
		hsl: 'hsl(20.1, 100%, 28.6%)',
		token: '@color-orange-900',
		type: 'dark',
	},
	{
		name: 'Navy 25',
		hex: '#F8F9F9',
		rgb: 'rgb(248, 249, 249)',
		hsl: 'hsl(180, 7.7%, 97.5%)',
		token: '@color-navy-25',
		type: 'light',
	},
	{
		name: 'Navy 50',
		hex: '#E9EBEC',
		rgb: 'rgb(233, 235, 236)',
		hsl: 'hsl(200, 7.3%, 92.0%)',
		token: '@color-navy-50',
		type: 'light',
	},
	{
		name: 'Navy 100',
		hex: '#C8CDD1',
		rgb: 'rgb(200, 205, 209)',
		hsl: 'hsl(207, 8.9%, 80.2%)',
		token: '@color-navy-100',
		type: 'light',
	},
	{
		name: 'Navy 200',
		hex: '#A8AFB5',
		rgb: 'rgb(168, 175, 181)',
		hsl: 'hsl(207.7, 8.1%, 68.4%)',
		token: '@color-navy-200',
		type: 'light',
	},
	{
		name: 'Navy 300',
		hex: '#7C8790',
		rgb: 'rgb(124, 135, 144)',
		hsl: 'hsl(213, 8.3%, 52.5%)',
		token: '@color-navy-300',
		type: 'light',
	},
	{
		name: 'Navy 400',
		hex: '#515F6B',
		rgb: 'rgb(81, 95, 107)',
		hsl: 'hsl(208, 13.8%, 36.9%)',
		token: '@color-navy-400',
		type: 'dark',
	},
	{
		name: 'Navy 500',
		hex: '#253746',
		rgb: 'rgb(37, 55, 70)',
		hsl: 'hsl(207.3, 30.8%, 21%)',
		token: '@color-navy-500',
		type: 'dark',
	},
	{
		name: 'Navy 600',
		hex: '#15293A',
		rgb: 'rgb(21, 41, 58)',
		hsl: 'hsl(208, 46.8%, 15.5%)',
		token: '@color-navy-600',
		type: 'dark',
	},
	{
		name: 'Navy 700',
		hex: '#081826',
		rgb: 'rgb(8, 24, 38)',
		hsl: 'hsl(208, 65.2%, 9%)',
		token: '@color-navy-700',
		type: 'dark',
	},
	{
		name: 'Cyan 50',
		hex: '#E5F6fA ',
		rgb: 'rgb(229, 246, 250)',
		hsl: 'hsl(191.4, 67.7%, 93.9%)',
		token: '@color-cyan-50',
		type: 'light',
	},
	{
		name: 'Cyan 100',
		hex: '#D3F0F7',
		rgb: 'rgb(149, 219, 236)',
		hsl: 'hsl(192, 69.6%, 75.5%)',
		token: '@color-cyan-300',
		type: 'light',
	},
	{
		name: 'Cyan 200',
		hex: '#B8E7F3',
		rgb: 'rgb(184, 231, 243)',
		hsl: 'hsl(192.2, 71.1%, 83.7%)',
		token: '@color-cyan-200',
		type: 'light',
	},
	{
		name: 'Cyan 300',
		hex: '#95DBEC',
		rgb: 'rgb(211, 240, 247)',
		hsl: 'hsl(192, 69.2%, 89.8%)',
		token: '@color-cyan-100',
		type: 'light',
	},
	{
		name: 'Cyan 400',
		hex: '#71CFE6',
		rgb: 'rgb(113, 207, 230)',
		hsl: 'hsl(192, 70.1%, 67.3%)',
		token: '@color-cyan-400',
		type: 'light',
	},
	{
		name: 'Cyan 500',
		hex: '#4EC3E0',
		rgb: 'rgb(78, 195, 224)',
		hsl: 'hsl(191.9, 70.2%, 59.2%)',
		token: '@color-cyan-500',
		type: 'light',
	},
	{
		name: 'Cyan 600',
		hex: '#2CB2D2',
		rgb: 'rgb(44, 178, 210)',
		hsl: 'hsl(191.6, 65.4%, 49.8%)',
		token: '@color-cyan-600',
		type: 'light',
	},
	{
		name: 'Cyan 700',
		hex: '#089BBE',
		rgb: 'rgb(8, 155, 190)',
		hsl: 'hsl(191.5, 91.9%, 38.8%)',
		token: '@color-cyan-700',
		type: 'dark',
	},
	{
		name: 'Cyan 800',
		hex: '#007D9A',
		rgb: 'rgb(0, 125, 154)',
		hsl: 'hsl(191.3, 100%, 30.2%)',
		token: '@color-cyan-800',
		type: 'dark',
	},
	{
		name: 'Cyan 900',
		hex: '#066C84',
		rgb: 'rgb(6, 108, 132)',
		hsl: 'hsl(191.4, 91.3%, 27.1%)',
		token: '@color-cyan-900',
		type: 'dark',
	},
	{
		name: 'Cyan 950',
		hex: '#125667',
		rgb: 'rgb(18, 86, 103)',
		hsl: 'hsl(192, 70.2%, 23.7%)',
		token: '@color-cyan-950',
		type: 'dark',
	},
	{
		name: 'Red 200',
		hex: '#FF8593',
		rgb: 'rgb(255, 133, 147)',
		hsl: 'hsl(355, 100%, 76.1%)',
		token: '@color-red-200',
		type: 'dark',
	},
	{
		name: 'Red 600',
		hex: '#C1001B',
		rgb: 'rgb(193, 0, 43)',
		hsl: 'hsl(346.6, 100%, 37.8%)',
		token: '@color-red-600',
		type: 'dark',
	},
	{
		name: 'Red 800',
		hex: '#783A3B',
		rgb: 'rgb(120, 58, 59)',
		hsl: 'hsl(359, 34.8%, 34.9%)',
		token: '@color-red-800',
		type: 'dark',
	},
	{
		name: 'Yellow',
		hex: '#FFE900',
		rgb: 'rgb(255, 233, 0)',
		hsl: 'hsl(54.8, 100%, 50%)',
		token: '@color-yellow',
		type: 'light',
	},
	{
		name: 'Dark Yellow',
		hex: '#C6B539',
		rgb: 'rgb(198, 181, 57)',
		hsl: 'hsl(355, 100%, 76.1%)',
		token: '@color-dark-yellow',
		type: 'light',
	},
	{
		name: 'Light Violet',
		hex: '#D6B9FF',
		rgb: 'rgb(214, 185, 255)',
		hsl: 'hsl(265, 100%, 86.3%)',
		token: '@color-light-violet',
		type: 'light',
	},
	{
		name: 'Light Blue',
		hex: '#6699CC',
		rgb: 'rgb(102, 153, 204)',
		hsl: 'hsl(210, 50%, 60%)',
		token: '@color-light-blue',
		type: 'dark',
	},
	{
		name: 'Medium Blue',
		hex: '#3992C6',
		rgb: 'rgb(57, 146, 198)',
		hsl: 'hsl(202, 55.3%, 50%)',
		token: '@color-medium-blue',
		type: 'dark',
	},
	{
		name: 'Light Green',
		hex: '#7FC5A2',
		rgb: 'rgb(127, 197, 162)',
		hsl: 'hsl(150, 37.6%, 63.5%)',
		token: '@color-light-green',
		type: 'light',
	},
	{
		name: 'Dark Green',
		hex: '#006E18',
		rgb: 'rgb(0, 110, 24)',
		hsl: 'hsl(133, 100%, 21.6%)',
		token: '@color-dark-green',
		type: 'dark',
	},
];
