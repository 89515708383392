<div
	id="search-container"
	class="search-container"
	(clickOutside)="onClickedOutside($event)"
	[exclude]="
		'search-input-container, search-icon, search-input, search-input-dropdown-container, search-input-dropdown-list, search-input-dropdown-item'
	"
>
	<div class="search-input-container">
		<kendo-svg-icon
			[icon]="searchIcon"
			class="search-icon"
		></kendo-svg-icon>
		<input
			#SearchInput
			class="search-input"
			autocomplete="off"
			name="search-input"
			value="{{ inputValue }}"
			(keyup)="onSearchInput()"
			type="text"
			id="search"
			role="combobox"
			aria-owns="results"
			aria-autocomplete="list"
			aria-expanded="inputDropDownShown"
			data-active-option="1"
			aria-activedescendant="1"
			placeholder="Search Shield"
		/>
		<div class="search-input-dropdown-container" *ngIf="inputDropDownShown">
			<div class="search-input-dropdown-list-header">
				{{
					seachResults.length === 0
						? 'No Results found'
						: 'Search
				Results:'
				}}
			</div>
			<ul
				id="results"
				role="listbox"
				*ngIf="seachResults.length > 0"
				class="search-input-dropdown-list"
			>
				<li
					class="search-input-dropdown-item"
					*ngFor="let item of seachResults; let i = index"
					[attr.data-index]="i"
					(click)="redirectTo(item.route)"
					role="option"
					[id]="i"
				>
					<span class="search-results-link">{{ item.name }}</span>
					<span class="search-results-router">{{
						item.route | formatCrumbs | titlecase
					}}</span>
				</li>
			</ul>
		</div>
	</div>
</div>
