<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				General best practices for writing content in and about BeyondTrust products. For copy guidance about a specific component or pattern, check the usage guidelines on each page.
			</li>
		</ul>
	</section>

	<section class="page-section" id="voice-tone">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('voice-tone')">
			Voice and tone<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Voice is how our writing sounds across an entire experience. It
				is part of our identity and never changes. Tone is the
				fluctuation of that voice that changes from one touchpoint of
				the experience to another.
			</li>
			<li class="page-section-list-item">
				You can think of our voice as our personality, and tone as our
				current mood.
			</li>

			<li class="page-section-list-item">
				Just as our tone might change between a sales pitch and a
				support email, the tone we use onboarding users will differ from
				the tone that we use in error notifications. We might use a more
				relaxed and friendly tone for onboarding, and a more apologetic
				one for error messages. No matter what, our voice remains the
				same.
			</li>
		</ul>
		<section class="page-sub-section" id="voice-tone-principles">
			<h3 id="subSectionTitleVTPrinciples" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('voice-tone-principles')">
				Voice principles<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					BeyondTrust's brand voice, as deteremined by our marketing team, is bold, confident, supportive and
					genuine. How does this brand voice manifest in our writing?
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Bold</span>
					We use strong, assertive language. We’re trustworthy and transparent.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Confident</span>
					We know what we’re talking about. We’re intelligent and informative, but never condescending.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Genuine</span>
					We’re authentic and sound like humans — not robots. We’re relatable, but not silly or irreverent.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Supportive</span>
					We’re approachable, while still empowering our users. We’re compassionate, yet still direct.
				</li>
				<li class="page-section-list-item">
					These principles are brought to life through our consistent
					choice of words, how many or little we use, and how we
					incorporate style elements like punctuation and
					capitalization.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="voice-tone-talking-users">
			<h3 id="subSectionTitleVoiceToneTalking" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('voice-tone-talking-users')">
				How to talk to users<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Don't use jargon or technical terms. Even if many of our users are experts, not all of them are. Plain language is best for everyone.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										That login information is incorrect.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Error 401. An authentication error occurred. Invalid credentials.

									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Use words that you would say
					naturally in conversation. Avoid internal lingo and choose simple words over complex ones.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										We'll let you know when the discovery is
										complete. You can explore the dashboard or
										results while you wait.
									</li>
									<li class="comparison-body-text-list-item">
										Cancel the scan
										<li class="comparison-body-text-list-item">
											Computer
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										You are free to navigate to other areas of
										the product. We will notify you when the
										discovery process is complete.
									</li>
									<li class="comparison-body-text-list-item">
										Abort the scan
									</li>
									<li class="comparison-body-text-list-item"> Machine

									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Give simple explanations and instructions, without being verbose. Lead by telling users why they should do something.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										 The system will be locked if not configured correctly. Double-check your information.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Any invalid configuration can render the
										system inaccessible. Please confirm the
										values that you have entered in the fields below.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Be succinct and focus on the need-to-know information. Aim to assure and educate, not patronize. Our users should feel knowledgeable and confident.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										 You can create more resource zones and brokers
										later.
										<li class="comparison-body-text-list-item">
											Roaming or cross-platform authenticators, like Yubikeys, are external security keys. Platform authenticators, such as Windows Hello or macOS Touch ID, are integrated and tied to a specific device. Both types of authenticators are FIDO2 certified and use biometrics instead of a password to log in.

									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Don't worry, you can create more
										resource zones and brokers later.
										<li class="comparison-body-text-list-item">
											Roaming authenticators, also known as cross-platform authenticators, are external, FIDO2-certified security keys, such as YubiKeys, that perform user verification using biometrics. They can be used instead of your password when logging into the BeyondInsight Console on any machine and supported operating system that allows the use of external FIDO2 authenticators. Platform authenticators are integrated, FIDO2-certified biometric authenticators, such as Windows Hello or macOS Touch ID. These authenticators are tied to the machine where you registered the authenticator. They can be used instead of your password when logging into the BeyondInsight Console.

									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Concision is important, but so is clarity. Use enough words to not sound
					curt, but don't hide behind lengthy sentences.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Policies at the top are the highest
										priority.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Policies on this computer are displayed in
										order of precedence, from the most important at the top to the least important at the bottom.
										<br />
										<br />
										<i>or</i>
										<br />
										<br />
										Policies ranked by importance.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Be mindful of rambling or redundancy.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Enter the email addresses of the users you'd like to invite.
									</li>
									<li class="comparison-body-text-list-item">
										You created the rule.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Enter the email address or addresses of the
										users you would like to invite as users.

									</li>
									<li class="comparison-body-text-list-item">
										Success! You successfully created the rule.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Do use second person, as if you're speaking directly to the
					user.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Go to your settings to update passwords.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Go to user settings to update passwords.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Acknowledge users’ effort and time, but don’t focus on being overly polite. Saying “please” every
					time action is required is unnatural. “Please” works best when we’re asking the user to do
					something truly inconvenient.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Thanks for filling out the form
									</li>
									<li class="comparison-body-text-list-item">
										Select messaging options
									</li>
									<li class="comparison-body-text-list-item">
										Please contact us
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Please fill out the form
									</li>
									<li class="comparison-body-text-list-item">
										Please select from options to the left
									</li>
									<li class="comparison-body-text-list-item">
										Please log in
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>

		<section class="page-sub-section" id="voice-tone-touchpoints">
			<h3 id="subSectionTitleVoiceToneTouchpoints" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('voice-tone-touchpoints')">
				Tone at different touchpoints<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					As mentioned, our tone changes throughout products. There are
					opportunities to use humor to make users smile, but often we
					should just get to the point. No matter what, we should be
					helpful and friendly.
				</li>
				<li class="page-section-list-item">
					We want users reading our copy to feel like there is a human
					guiding them through a product.
				</li>
				<li class="page-section-list-item">
					We don't want to sound robotic or too technical, but we also
					don't want to sound too casual&#8212;like we aren't taking
					security seriously. Striking the right balance between a relaxed
					and formal tone can be difficult.
				</li>
				<li class="page-section-list-item">
					Here are some examples of “getting it right”:
				</li>
			</ul>

			<section class="page-sub-section" id="tone-touchpoints-404">
				<h3 id="subSectionTitleToneTouchpoints404" class="page-sub-section-title" title="Copy Link to Clipboard"
					(click)="copyToClipboard('tone-touchpoints-404')">
					404<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
					</svg>
				</h3>
				<ul class="page-section-list">
					<li class="page-section-list-item">
						<b>Too casual</b>: Oops! Nothing found.
					</li>
					<li class="page-section-list-item">
						<b>Too formal</b>: 404. We apologize for the inconvenience.
						If you believe this is an error, contact support.
					</li>
					<li class="page-section-list-item">
						<b>Just right</b>: Page not found. It was either moved,
						removed, or never existed. Sorry for the interruption, we'll
						let you get back to saving the world.
					</li>
				</ul>
			</section>

			<section class="page-sub-section" id="tone-touchpoints-empty-state">
				<h3 id="subSectionTitleToneTouchpointsEmptyState" class="page-sub-section-title"
					title="Copy Link to Clipboard" (click)="copyToClipboard('tone-touchpoints-empty-state')">
					Empty state<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
					</svg>
				</h3>
				<ul class="page-section-list">
					<li class="page-section-list-item">
						Empty state messages should present an opportunity to a user
						by showing what the space can do for them. They shouldn't be
						robotic, but they shouldn't be too casual either.
					</li>
					<li class="page-section-list-item">
						<b>Too casual:</b> It's looking lonely in here! What should
						we connect first?
					</li>
					<li class="page-section-list-item">
						<b>Too formal:</b> No connectors added.
					</li>
					<li class="page-section-list-item">
						<b>Just right:</b> Start connecting to your other security
						platforms to get all your identity data in one place.
					</li>
				</ul>

			</section>
			<section class="page-sub-section" id="tone-touchpoints-no-results-search">
				<h3 id="subSectionTitleToneTouchpointsNoResultsSearch" class="page-sub-section-title"
					title="Copy Link to Clipboard" (click)="copyToClipboard('tone-touchpoints-no-results-search')">
					No results <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
					</svg>
				</h3>
				<ul class="page-section-list">
					<li class="page-section-list-item">
						No results messages for search should clearly tell users
						nothing was found and offer suggestions about what they can
						try instead to find results. If there are filters applied, let the user know they need to edit
						them. They shouldn't be too
						technical, but be specific about the type of data they're trying to access.
					</li>
					<li class="page-section-list-item">
						<b>Too casual:</b> Hmm, nothing to see here!
					</li>
					<li class="page-section-list-item">
						<b>Too formal:</b> Your query produced no results. Enter new
						values.
					</li>
					<li class="page-section-list-item">
						<b>Just right:</b> No rules matched your search. Try
						searching by another keyword.
					</li>
				</ul>
			</section>
			<section class="page-sub-section" id="tone-touchpoints-welcome">
				<h3 id="subSectionTitleToneTouchpointsWelcome" class="page-sub-section-title"
					title="Copy Link to Clipboard" (click)="copyToClipboard('tone-touchpoints-welcome')">
					Welcome<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
					</svg>
				</h3>
				<ul class="page-section-list">
					<li class="page-section-list-item">
						Welcome messages are a chance to build excitement and
						briefly introduce what a product can do for a user, as well
						as direct them to resources.
					</li>
					<li class="page-section-list-item"><b>Header:</b> Welcome!</li>
					<li class="page-section-list-item">
						<b>Description:</b> You can now start rolling out and
						managing your privilege management solution. Select the help
						icon at any time to get guides, documentation, and support.
					</li>
					<li class="page-section-list-item"><b>Button:</b> Let's go</li>
				</ul>
			</section>

			<section class="page-sub-section" id="tone-touchpoints-whats-new">
				<h3 id="subSectionTitleToneTouchpointsWhatsNew" class="page-sub-section-title"
					title="Copy Link to Clipboard" (click)="copyToClipboard('tone-touchpoints-whats-new')">
					"What's new"<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
					</svg>
				</h3>
				<ul class="page-section-list">
					<li class="page-section-list-item">
						"What's new" messages should focus on what the user benefits
						from the changes. There's no need to get too technical, but you can direct the
						user to more information.
					</li>
					<li class="page-section-list-item">
						<b>Header:</b> We've made some improvements
					</li>
					<li class="page-section-list-item">
						<b>Description:</b> The new Privilege Management for Windows
						and Mac includes features and enhancements that make it
						easier to understand behavior data. Plus, seamlessly refine
						your policies, onboard endpoints, and access your license
						key information.
					</li>
					<li class="page-section-list-item">
						Check out the <b>What's New</b> section of the Knowledge
						Center or read the release notes.
					</li>
					<li class="page-section-list-item"><b>Button:</b> Got it</li>
				</ul>
			</section>
		</section>
		<section class="page-section" id="grammar-spelling">
			<h2 id="sectionTitleGrammarSpelling" class="page-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('grammar-spelling')">
				Grammar and spelling<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h2>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					While content is often localized, use American English for consistency.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Organized
									</li>
									<li class="comparison-body-text-list-item">
										Behavior
									</li>
									<li class="comparison-body-text-list-item">
										Adapter
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Organised
									</li>
									<li class="comparison-body-text-list-item">
										Behaviour
									</li>
									<li class="comparison-body-text-list-item">
										Adaptor
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Use simple contractions to sound natural. Avoid less common ones.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										You're responsible for storing the
										Client Secret in a secure location. This
										is the only time you'll be able to view
										the Client Secret.
									</li>
									<li class="comparison-body-text-list-item">
										Must not.
									</li>
									<li class="comparison-body-text-list-item">
										That will.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										You are responsible for storing the
										Client Secret in a secure location. This
										is the only time you will be able to
										view the Client Secret.
									</li>
									<li class="comparison-body-text-list-item">
										Mustn't.
									</li>
									<li class="comparison-body-text-list-item">
									 That'll.
									</li>
								</ul>
							</div>
						</li>
					</ul>


				</li>
				<li class="page-section-list-item">
					When there is a possibility for more than one item, don’t use a (s) in parentheses or a slash with the plural form. Choose the
					plural, even if the user might only select one thing.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Updates
									</li>
									<li class="comparison-body-text-list-item">
										Reboots
									</li>
									<li class="comparison-body-text-list-item">
										Policies
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Update(s)
									</li>
									<li class="comparison-body-text-list-item">
										Reboot(s)
									</li>
									<li class="comparison-body-text-list-item">
										Policy/ies
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
			<section class="page-sub-section" id="grammar-spelling-sentence">
				<h3 id="sectionTitleGrammarSpellingSentence" class="page-sub-section-title"
					title="Copy Link to Clipboard" (click)="copyToClipboard('grammar-spelling-sentence')">
					Sentence structure<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
					</svg>
				</h3>

				<ul class="page-section-list">
					<li class="page-section-list-item">
						Start with “If” so that users can skip over the content if
						it doesn't apply to them.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											If you want to include the new update,
											delete the existing schedule and create
											a new one.
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Delete the existing schedule and create
											a new one if you want to include the new
											update.
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						Think about using the active voice instead of the passive voice. The active
						voice is when the subject is the main focus of the sentence, not
						the object or action.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Scan times are in your local time zone.
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Scan times are localized to the scanner time
											zone.
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						Use complete sentences but keep them simple and succinct.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Go to <strong> Profile</strong> and select <strong>Settings</strong>.
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Go to <b>Profile</b>. Select <b>Settings</b>.
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
				</ul>
			</section>
			<section class="page-sub-section" id="grammar-spelling-punctuation">
				<h3 id="subSectionTitleGrammarSpellingPunctuation" class="page-sub-section-title"
					title="Copy Link to Clipboard" (click)="
					copyToClipboard('grammar-spelling-punctuation')
				">
					Punctuation<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
					</svg>
				</h3>
				<ul class="page-section-list">
					<li class="page-section-list-item">
						Avoid asterisks. When information is hidden in the fine
						print, it reduces trust.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Add up to 3 users.
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Add users*
											<br />
											<br />
											*You can only add 3 users.
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						Use periods to end complete sentences. Don't use periods in headings or labels. In bulleted or numbered lists, use periods when the list item is a full sentence, not a single word or short phrase.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item" style="
											list-style: none;
											margin-left: -20px;
										">
											What does Insights offer?
										</li>
										<li class="comparison-body-text-list-item">
											Gives unparalleled visibility into your identities, accounts, and privileged access.  										</li>
										<li class="comparison-body-text-list-item">
											Detects suspicious activity from high-risk privileged accounts.  

										</li>
										<li class="comparison-body-text-list-item">
											Proactively recommends actions to reduce your attack surface.
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item" style="
											list-style: none;
											margin-left: -20px;
										">
											What does Insights offer?
										</li>
										<li class="comparison-body-text-list-item">
											Identity and account visibility.
										</li>
										<li class="comparison-body-text-list-item">
											Detections.
										</li>
										<li class="comparison-body-text-list-item">
											Recommendations.
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						Exclamation marks are often overused. Limit 1-2 per screen or better yet, save them
						for truly exciting parts of the experience.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Welcome!
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Discovery is now in progress!
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						You can use quotation marks to indicate the name of an item. When referring to section titles on a UI, use bold instead of quotation marks. Punctuation should nearly always go inside the quotation.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											You created the group "Halifax Office."
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											To delete, select "Delete group".
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						Do use commas in sentences, not lists.
					</li>
					<li class="page-section-list-item">
						Use serial commas, also known as an Oxford comma, after the
						“and” in sentences that list 3 or more items. While it can
						be tempting to drop the serial comma to save space, it can
						also make things more confusing by not separating the last
						items in a list.
					</li>
					<li class="page-section-list-item">
						Without the serial comma in the first example, it's not
						clear that recommendations and detections are two different
						things.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Use Insights to view entitlements,
											recommendations, and detections.
										</li>
										<li class="comparison-body-text-list-item">
											Configure other details such as screen
											resolution, session node, smart sizing,
											and more.
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Use Insights to view entitlements,
											recommendations and detections.
										</li>
										<li class="comparison-body-text-list-item">
											Configure other details such as screen
											resolution, session node, smart sizing
											and more.
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						Use em dashes (the long ones) for emphasis and en dashes
						(the short ones) to specify a range. Don't use spaces for en
						dashes. You can use spaces for em dashes if there is enough
						room.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											5-10 administrators
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											5&#8212;10 administrators
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						Use colons, not semicolons, to introduce bulleted lists or as part of a definition.
						The rules around semicolons in sentences can be hard to remember. When in doubt, just use a period or comma instead.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item" style="
											list-style: none;
											margin-left: -20px;
										">
											Configure details:
										</li>
										<li class="comparison-body-text-list-item">
											Screen resolution
										</li>
										<li class="comparison-body-text-list-item">
											Session node
										</li>
										<li class="comparison-body-text-list-item">
											Smart sizing
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item" style="
											list-style: none;
											margin-left: -20px;
										">
											Configure details;
										</li>
										<li class="comparison-body-text-list-item">
											Screen resolution,
										</li>
										<li class="comparison-body-text-list-item">
											Session node,
										</li>
										<li class="comparison-body-text-list-item">
											Smart sizing,
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						Avoid ampersands, write “and” instead. Ampersands draw
						attention to the least important part of the text and should
						only be used for titles. Similarly, choose "or" or "and" instead of using a "/".
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Enter details for known systems and
											accounts
										</li>
										<li class="comparison-body-text-list-item">
											Systems & Accounts
										</li>
										<li class="comparison-body-text-list-item">
											Active and completed scans
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Enter details for known systems &
											accounts
										</li>
										<li class="comparison-body-text-list-item">
											Active/completed scans
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
				</ul>
			</section>
			<section class="page-sub-section" id="grammar-spelling-capitalization">
				<h3 id="subSectionTitleGrammarSpellingCapitalization" class="page-sub-section-title"
					title="Copy Link to Clipboard" (click)="
					copyToClipboard('grammar-spelling-capitalization')
				">
					Capitalization<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
					</svg>
				</h3>
				<ul class="page-section-list">


					<li class="page-section-list-item">
						Only capitalize proper nouns.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Password Safe lets users configure Smart Rule filters.
										</li>
										<li class="comparison-body-text-list-item">
											Insights lets users manage tenants in their organization.
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Password Safe lets users configure smart rule filters.
										</li>
										<li class="comparison-body-text-list-item">
											Insights lets users manage Tenants in their Organization.
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						Don't use all caps in the middle of a sentence for emphasis,
						or as labels for buttons.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											If all of the conditions are met.
										</li>
										<li class="comparison-body-text-list-item">
											Try Insights for free.
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											If ALL of the conditions are met.
										</li>
										<li class="comparison-body-text-list-item">
											Try Insights for FREE.
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						Title case is when you capitalize the first letter of most
						words, sentence case is when you only capitalize the first
						letter of the first word.
					</li>

					<ul class="page-section-list" style="padding-left: 20px">
						<li class="page-section-list-item" style="list-style: disc">
							This is an Example of Title Case
						</li>
						<li class="page-section-list-item" style="list-style: disc">
							This is an example of sentence case.
						</li>
					</ul>

					<li class="page-section-list-item">
						Sentence case is highly favoured by tech companies as it is
						more readable, easier to localize, and has less rules to
						remember. For these reasons, we should move towards sentence
						case everywhere.

					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Leader in intelligent identity and
											access security
										</li>
										<li class="comparison-body-text-list-item">
											Dangerously outdated browser used
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Leader In Intelligent Identity And
											Access Security
										</li>
										<li class="comparison-body-text-list-item">
											Dangerously Outdated Browser Used
										</li>
									</ul>
								</div>
							</li>
						</ul>
				</ul>
			</section>
			<section class="page-sub-section" id="grammar-spelling-formatting">
				<h3 id="subSectionTitleGrammarSpellingFormatting" class="page-sub-section-title"
					title="Copy Link to Clipboard" (click)="
					copyToClipboard('grammar-spelling-formatting')
				">
					Formatting<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
					</svg>
				</h3>
				<ul class="page-section-list">
					<li class="page-section-list-item">
						Do nearly always use numerals. Don't use superscript for
						ordinal numbers.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											You can view up to 10,000 events.
										</li>
										<li class="comparison-body-text-list-item">
											Third party
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											You can view up to ten thousand events.
										</li>
										<li class="comparison-body-text-list-item">
											3<sup>rd</sup> party
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						Do use bulleted lists and numbered lists to make content more
						scannable. Use numbers for sequential lists. Don't include
						blocks of text that are more than 3-4 lines long or 50
						characters wide.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ol class="comparison-body-text-list">
										<li class="comparison-body-text-list-item" style="list-style: decimal">
											Enter 3 or more characters to search for the
											user's first name, last name or description.
										</li>
										<li class="comparison-body-text-list-item" style="list-style: decimal">
											Check the box to assign the user to the
											selected group.
										</li>
									</ol>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Please enter three or more characters to
											begin searching against user's first name,
											last name or description. If a user is
											already assigned to all of the currently
											selected groups, their result will display
											as checked. If the user is not assigned to
											one or more of the currently selected
											groups, their result will display as
											unchecked. Check the box to assign that user
											to all currently selected groups.
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
					<li class="page-section-list-item">
						When giving instructions, do use bold type to refer to
						titles or labels on a UI, not quotation marks. You can also use bold for emphasis.
					</li>
					<li class="page-section-list-item">
						<ul class="page-section-comparison-list">
							<li class="page-section-comparison-list-item comparison-do">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

									Try this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Select <b>Create Rule</b> under
											Management Rules.
										</li>
									</ul>
								</div>
							</li>
							<li class="page-section-comparison-list-item comparison-do-not">
								<div class="page-section-comparison-header">
									<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
									Not this
								</div>
								<div class="page-section-comparison-body">
									<ul class="comparison-body-text-list">
										<li class="comparison-body-text-list-item">
											Select “Create Rule” under “Management
											Rules”
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</li>
				</ul>
			</section>
		</section>
		<section class="page-section" id="word-choice">
			<h2 id="sectionTitleWordChoice" class="page-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('word-choice')">
				Word choice<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h2>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Avoid using and/or. And/or is a legalise term that often leads to confusion and ambiguity. Instead,
					pick one or the other, or replace with “x or y, or both.”
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Choose a user or a group, or both.

									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Choose a user and/or group
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Avoid adverbs when writing instructions. While there are times to use descriptive words to talk
					about our products, we don’t want to make assumptions about the user’s level of understanding or how
					the process is going for them. </li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Add known systems
									<li class="comparison-body-text-list-item">
										Create the system

									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Quickly and easily add known systems
									<li class="comparison-body-text-list-item">
										Simply create the system
									</li>
								</ul>
							</div>
						</li>
					</ul>

				</li>
				<li class="page-section-list-item">
					If space is limited, consider cutting words and articles (the, an, a).
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Start scan
									</li>
									<li class="comparison-body-text-list-item">
										Copy details
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Start a scan
									</li>
									<li class="comparison-body-text-list-item">
										Copy the details
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="word-choice-actions">
			<h3 id="subSectionTitleWordChoiceActions" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('word-choice-actions')">
				Calls to action (CTAs)<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>

			<ul class="page-section-list">
				<li class="page-section-list-item">
					Interfaces with actions like creating, editing, or deleting should have a title that clearly states the action and the name of the item. Be specific. There’s usually no need to include the
					word “new” — it’s implied, since anything created is new.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Create GitHub Connector
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Create New Connector
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Editing actions should indicate what's being edited. The label should be <b>Edit ___</b>. For inline editing, the field name should be specified. If the action opens a panel, then the title of the panel should also include what is being edited. The confirmation button should be <b>Save Changes</b>.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Title or label: Edit message name
									</li>
									<li class="comparison-body-text-list-item">
									Button: Save changes
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Title or label: Edit property
									</li>
									<li class="comparison-body-text-list-item">
										Button: Confirm
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					The title should match or mirror the button. Some items with subcategories of data might have
					advanced views with several titles. In this case, the title might differ — as long as
					the main title contains the item name.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Title: Add IP addresses to allow list
										Button: Add addresses
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Title: Add IP addresses to allow list
										Button: Update list
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					Do use strong verbs to encourage action. Generally, put the
					verb first.  
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Assign policy to groups
									</li>
									<li class="comparison-body-text-list-item">
										Edit policy
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Groups: Assign policy
									</li>
									<li class="comparison-body-text-list-item">
										Policies: Edit
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					If no action can be taken and a user is just viewing an existing item, the title can just be the
					name of the item.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Activity History
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										View Activity History
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="word-choice-links-labels">
			<h3 id="subSectionTitleWordChoiceLinksLabels" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('word-choice-links-labels')">
				Links and labels<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>

			<ul class="page-section-list">
				<li class="page-section-list-item">
					Clearly describe where <a href="components/text-link">links</a> will take a user. Links that just say "Learn more" are known as
					“lazy links” — they're inaccessible, ambiguous, and create uncertainty. Use descriptive and detailed
					links instead.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Need help? <a>Read our FAQ</a> or
										<a>contact support.</a>
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Need help? <a>Click here.</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					When an item has a long name, like a file, always use the type of item.
				</li>
				<li class="page-section-list-item">
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

								Try this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Edit Exclusion
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Edit C:\Windows\System32\app.exe
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>
	</section>

	<section class="page-section" id="accessibility-inclusion">
		<h2 id="sectionTitleAccessibilityInclusion" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('accessibility-inclusion')">
			Accessibility and inclusion<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Don't use black to refer to something negative and white to
				something positive.
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

							Try this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Ethical hacker
								</li>
								<li class="comparison-body-text-list-item">
									Block list
								</li>
								<li class="comparison-body-text-list-item">
									Allow list
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									White hat hacker
								</li>
								<li class="comparison-body-text-list-item">
									Black list
								</li>
								<li class="comparison-body-text-list-item">
									White list
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</li>
			<li class="page-section-list-item">
				Do use gender neutral language.
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

							Try this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									They/them/theirs
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									She/her/hers
								</li>
								<li class="comparison-body-text-list-item">
									He/him/his
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</li>
			<li class="page-section-list-item">
				When we use disable to describe a
				feature that is turned off or not functioning, we reinforce the
				idea that being disabled is lesser or negative.
			</li>
			<li class="page-section-list-item">
				Since disabled is an HTML attribute it can be appropriate in the
				context of development and in internal documentation. There are
				also non-BeyondTrust applications and systems that use disable
				that we may have to reference. While the term disabled has previously been an industry standard, the industry is changing and we want to be part of that change.
			</li>
			<li class="page-section-list-item">
				Always consider an alternative when writing user-facing content.
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

							Try this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Activate or On
								</li>
								<li class="comparison-body-text-list-item">
									Deactivate or Off
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Enable
								</li>
								<li class="comparison-body-text-list-item">
									Disabled
								</li>
							</ul>
						</div>
					</li>
				</ul>


			</li>
			<li class="page-section-list-item">
				Write simply to accommodate lower reading comprehension.
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

							Try this
						</div>
						<div class="page-section-comparison-body">
							<ol class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									You'll get this notification if a
									performance indicator exceeds one of your
									set thresholds for a long period of time.
									For example, CPU going below 80%.
								</li>
							</ol>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									You will receive this notification if a
									performance indicator you have configured
									exceeds one of the thresholds you have set
									(for example, CPU passing 80% as a medium
									alert). These alerts will be sent when the
									performance indicator drops below 80% for a
									sustained period.
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</li>
		<li class="page-section-list-item">
			Acronyms don't localize well and aren't always screen reader friendly. They also often take more cognitive effort to deciper and remember. If you need to use an acronym, always spell it out first and use the acronym in brackets.
		</li>
		<li class="page-section-list-item">
			<ul class="page-section-comparison-list">
				<li class="page-section-comparison-list-item comparison-do">
					<div class="page-section-comparison-header">
						<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

						Try this
					</div>
					<div class="page-section-comparison-body">
						<ol class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Start a Remote Desktop Protocol (RDP) session.
							</li>
						</ol>
					</div>
				</li>
				<li class="page-section-comparison-list-item comparison-do-not">
					<div class="page-section-comparison-header">
						<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
						Not this
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Start a RDP session.
							</li>
						</ul>
					</div>
				</li>
			</ul>
		</li>
			<li class="page-section-list-item">
				Don't use verbs like “click” or “tap”—not all users can
				physically interact with a mouse or screen.
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>

							Try this
						</div>
						<div class="page-section-comparison-body">
							<ol class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Select <strong>create directory credential.</strong>
								</li>
							</ol>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Click <strong>create directory credential.</strong>
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</li>
			<li class="page-section-list-item">
				Do think carefully about the idioms you use. Many idioms have
				harmful connotations.
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-comparison-list" style="margin-bottom:0px;">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
							Try this
						</div>
						<div class="page-section-comparison-body">
							<ol class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Legacy
								</li>
							</ol>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Grandfathered in
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</li>
		</ul>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
				<a href="patterns/filter">Filter pattern</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/text-link">Text link component</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Content Guidelines'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
