<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Toast'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="success">Success</option>
					<option value="info">Informational</option>
					<option value="warning">Warning</option>
					<option value="error">Error</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<ng-template #toastControlsTemplate>
					<div class="sh-toast-controls">
						<kendo-button [svgIcon]="pauseIcon" themeColor="primary" fillMode="flat" (click)="pauseAll()">
							Pause </kendo-button>
						<kendo-button [svgIcon]="resumeIcon" themeColor="primary" fillMode="flat" (click)="resumeAll()">
							Resume </kendo-button>
						<kendo-button [svgIcon]="closeIcon" themeColor="primary" fillMode="flat" (click)="dismissAll()">
							Dismiss all </kendo-button>
					</div>
				</ng-template>
				<div class="toast-type-buttons">
					<kendo-button (click)="showToast()" themeColor="primary">
						<ng-container *ngIf="currLive === 'success'">Success</ng-container>
						<ng-container *ngIf="currLive === 'info'">Informational</ng-container>
						<ng-container *ngIf="currLive === 'warning'">Warning</ng-container>
						<ng-container *ngIf="currLive === 'error'">Error</ng-container>
					</kendo-button>
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDevResources" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-started">
			<h3 id="subSectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			Added to your project's app.module.ts file:
			<code
				style="margin-top: 10px">import &#123; NotificationModule &#125; from '&#64;progress/kendo-angular-notification';</code>
			<code
				style="margin-top: 10px">import &#123; ProgressBarModule &#125; from '&#64;progress/kendo-angular-progressbar';</code>
		</section>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark">
				{{ toastHTML }}
			</pre>
			<pre prism class="dark">{{ toastTs }}</pre>
			<pre prism class="dark">{{ toastTemplateTs }}</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank"
						href="https://www.telerik.com/kendo-angular-ui/components/notification/api/"><svg
							xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500"
								d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z">
							</path>
							<path fill="#5ce500"
								d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z">
							</path>
						</svg>Kendo Notification API</a>
				</li>
				<li class="page-section-list-item">
					Refer to the
					<a href="components/toast#behaviors">behaviors
						section</a>
					to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultAll;" [data]="item">
						</app-style-row>
					</ul>
					<span class="page-style-list-title">Informational</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultInfo;" [data]="item">
						</app-style-row>
					</ul>
					<span class="page-style-list-title">Success</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultSuccess;" [data]="item">
						</app-style-row>
					</ul>
					<span class="page-style-list-title">Warning</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultWarning;" [data]="item">
						</app-style-row>
					</ul>
					<span class="page-style-list-title">Error</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultError;" [data]="item">
						</app-style-row>
					</ul>
					<span class="page-style-list-title">Text Link (All variations)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of textLinkStylesDefault;" [data]="item">
						</app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkAll;" [data]="item">
						</app-style-row>
					</ul>
					<span class="page-style-list-title">Informational</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkInfo;" [data]="item">
						</app-style-row>
					</ul>
					<span class="page-style-list-title">Success</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkSuccess;" [data]="item">
						</app-style-row>
					</ul>
					<span class="page-style-list-title">Warning</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkWarning;" [data]="item">
						</app-style-row>
					</ul>
					<span class="page-style-list-title">Error</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkError;" [data]="item">
						</app-style-row>
					</ul>
					<span class="page-style-list-title">Text Link (All variations)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of textLinkStylesDark;" [data]="item">
						</app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography;" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Text Link (All variations)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of textLinkStylesTypography;" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure;" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Text Link (All variations)</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of textLinkStylesStructure;" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-duration">
			<h3 id="subSectionTitleBehaviorsDuration" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-duration')">
				Duration <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Toasts should appear for at least 6-8 seconds. This is an accessibility standard to make
					sure the user has enough time to read the content.
				</li>
				<li class="page-section-list-item">
					There is a progress indicator at the top of the toast that lasts 8 seconds. It stops counting
					down when the toast is paused, then starts again on resume.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-pause-resume">
			<h3 id="subSectionTitleBehaviorsPauseResume" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-pause-resume')">
				Pause/resume <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Toasts have a notification options bar and a Pause/Resume feature that allows users to pause
					toasts if they do require more time to read the content.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-multiple">
			<h3 id="subSectionTitleBehaviorsMultiple" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-multiple')">
				Multiple toasts <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					If there’s already a visible toast message a newly triggered toast will appear below it.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-actions">
			<h3 id="subSectionTitleBehaviorsActions" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-actions')">
				Actions and dismiss <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					All toasts have a text link labeled <strong>Dismiss</strong> that removes the toast message. Toasts
					can
					also contain another optional action to the left of the <strong>Dismiss</strong> text link that
					takes the
					user to other content. If the toast contains critical information, you might want to choose a
					different
					label that asks the user to acknowledge the message. Try <strong>Got It</strong> or <strong>I
						Understand</strong>.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-dismiss-all">
			<h3 id="subSectionTitleBehaviorsDismissAll" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-dismiss-all')">
				Dismiss all <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Users can dismiss all toast notifications by selecting <strong>Dismiss All</strong> from the
					notifications
					options bar. This option is located to the right of <strong>Pause</strong> and
					<strong>Resume</strong>.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-focus">
			<h3 id="subSectionTitleBehaviorsFocus" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-focus')">
				Focus <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When the toast’s text links are focused on, a dashed outline replaces the browser default focus
					state
					for links.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-animation">
			<h3 id="subSectionTitleBehaviorsAnimation" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-animation')">
				Animation <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Toast messages appear and are dismissed with an animation lasting 250ms as mentioned in the <a
						href="guidelines/motion">motion guidelines</a>.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-info">
			<h3 id="subSectionTitleVariationsInfo" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-info')">
				Informational <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Informational toasts are only for relatively low priority messaging. Nothing has failed or
					succeeded, but something
					neutral has occurred which the user should be made aware of.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-success">
			<h3 id="subSectionTitleVariationsSuccess" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-success')">
				Success <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Success toasts inform the user of successful or positive actions within the system.
				</li>
			</ul>
		</section>


		<section class="page-sub-section" id="variations-warning">
			<h3 id="subSectionTitleVariationsWarning" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-warning')">
				Warning <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Warning toasts pre-emptively inform the user of potentially dangerous or destructive actions. They
					can also indicate
					relatively important details about system outages or temporary or reduced functionality. The warning
					icon uses different colors than the rest of the light mode toasts in order to meet accessibility
					requirements.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-error">
			<h3 id="subSectionTitleVariationsError" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-error')">
				Error <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Error toasts inform a user that the action they attempted to perform encountered a problem.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-do-list-title">Do:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Use toasts for submissions of an entire form.
						</li>
						<li class="page-section-list-item">
							Use toasts for events that occur “behind the scenes.”
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top: 5px">
					<span class="page-section-do-list-title">Don't:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Use toasts when a user completes individual fields on a form.
						</li>
					</ul>
				</li>
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
							Try This
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Form submitted
								</li>
								<li class="comparison-body-text-list-item">
									Copied to clipboard
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Tenant ID entered successfully
								</li>
								<li class="comparison-body-text-list-item">
									Field validated
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Toast messages should be brief, while still providing context to the user. The content in a
					toast message should be informative, relevant, and timely. Aim for 15 words or fewer.
				</li>
				<li class="page-section-list-item">
					Informational messages should be specific when possible and provide instruction.
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										You didn’t make any changes, so nothing new needs to be saved.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										No action needed.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top:10px;">
					Success toast messages should help provide certainty. They typically don’t need to contain the word
					success or successfully — it’s redundant. Again, be specific when possible. Try to speak directly to
					the user in first person and using the active voice.
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										10 users added to "Halifax Office."
									</li>
									<li class="comparison-body-text-list-item">
										Invitation sent to auggie&#64;beyondtrust.com.
									</li>
									<li class="comparison-body-text-list-item">
										You saved changes to "Halifax Office."
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Success! 10 users added to the group successfully.
									</li>
									<li class="comparison-body-text-list-item">
										Invitation was successfully emailed to the user.
									</li>
									<li class="comparison-body-text-list-item">
										Changes were successfully saved.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top:10px;">
					Warning messages should inform the user about what impact the warning will have on them, or any
					action they can take.
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										An outage is planned for April 6 at 3pm EST.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										A planned outage is scheduled.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top:10px;">
					Error toast messages can explain what happened, without using error code or jargon.
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Group could not be created. Name already exists.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Error. Could not validate group name field.
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top:10px;">
					Use toast messages to confirm when something is deleted or discarded. Even though they are
					confirming
					a successful action, the tone should not be celebratory. No need to say “success”.
					<ul class="page-section-comparison-list">
						<li class="page-section-comparison-list-item comparison-do">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
								Try This
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										You discarded all changes.
									</li>
									<li class="comparison-body-text-list-item">
										“Halifax Office” deleted.
									</li>
								</ul>
							</div>
						</li>
						<li class="page-section-comparison-list-item comparison-do-not">
							<div class="page-section-comparison-header">
								<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
								Not this
							</div>
							<div class="page-section-comparison-body">
								<ul class="comparison-body-text-list">
									<li class="comparison-body-text-list-item">
										Success! Discard complete.
									</li>
									<li class="comparison-body-text-list-item">
										Group was successfully deleted!
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-positioning">
			<h3 id="subSectionTitleUsagePositioning" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-positioning')">
				Positioning <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					The list of toast messages starts in the top right of the page, just below the header.
					The list expands downward, with the newest message appearing below the last but aligned
					in a vertical list.
				</li>
				<li class="page-section-list-item">
					Longer toast messages wrap onto multiple lines.The <strong>Dismiss</strong> button,
					icon, and any other links should align with the top line of the text.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-icons">
			<h3 id="subSectionTitleUsageIcons" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-icons')">
				Icons <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Toasts have a corresponding icon to the left of the message that indicates what type of
					message it is, like the checkmark for success. Don’t use other icons.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-toasts-vs-banners">
			<h3 id="subSectionTitleUsageToastsVsBanners" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-toasts-vs-banners')">
				Toasts versus banners <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Toasts are immediate reactions to a user action. Banners display messages that are often a summary
					or notification information generated by the system on a page load or refresh.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/text-link">Text link component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/motion">Motion guidelines</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
					let subItem of changelogService.getChangelogBySection('Toast Component')
				">
				<span class="page-history-list-item-title">
					{{ changelogService.formatDate(subItem.changeDate) }}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
						'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
						subItem.workId">
					[{{ subItem.workId }}]
				</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>