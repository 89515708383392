import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.less'],
})
export class ContactComponent {
	constructor(private viewportScroller: ViewportScroller) {}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}
}
