import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';

// Data Sources
import {
	textLinkStylesDefaultTheme,
	textLinkStylesDarkTheme,
	textLinkStylesTypography,
	textLinkStylesStructure,
} from './text-link-styles';
import {
	shCheck,
	shSquareArrowUpRight,
	shXmark,
} from '@beyondtrust/shield-icons';
import { ChangelogService } from 'src/app/services/changelog.service';

@Component({
	selector: 'app-text-link',
	templateUrl: './text-link.component.html',
	styleUrls: ['./text-link.component.less'],
})
export class TextLinkComponent {
	public linkIcon = shSquareArrowUpRight;
	public doThisIcon = shCheck;
	public notThisIcon = shXmark;
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefault = textLinkStylesDefaultTheme;
	public stylesDark = textLinkStylesDarkTheme;
	public stylesTypography = textLinkStylesTypography;
	public stylesStructure = textLinkStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Visited',
					value: 'visited',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Position',
					value: 'position',
				},
				{
					name: 'Text links with icons',
					value: 'icons',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		public changelogService: ChangelogService,
		private clipboardService: ClipboardService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	linkDefaultHTML = `
<a href="components/text-link#">View Full Details</a>`;

	linkDisabledHTML = `
<a aria-disabled="true" disabled>View Full Details</a>
<a aria-disabled="true" class="disabled">View Full Details</a>`;

	linkIconHTML = `
<a href="components/text-link#">Go to External Site
	<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
</a>`;
}
