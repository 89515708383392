// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: errorPageStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const errorPageStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const errorPageStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const errorPageStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const errorPageStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const errorPageStylesDefaultThemeOld = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
];

export const errorPageStylesDarkThemeOld = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
];

export const errorPageStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: 'Open Sans, Arial, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Title',
		property: 'font-size',
		value: '88px',
		isColorTile: false,
	},
	{
		name: 'Subtitle',
		property: 'font-size',
		value: '20px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 600',
		isColorTile: false,
	},
];

export const errorPageStylesStructureOld = [
	{
		name: 'Title',
		property: 'margin-top',
		value: '60px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-bottom',
		value: '24px',
		isColorTile: false,
	},
	{
		name: 'Horizontal Rule',
		property: 'margin-top',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-bottom',
		value: '24px',
		isColorTile: false,
	},
];
