import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import * as ShieldIcons from '@beyondtrust/shield-icons';
import { SVGIcon } from '@beyondtrust/shield-icons';

// Data Sources
import {
	cardStylesDefaultTheme,
	cardStylesDarkTheme,
	cardStylesTypography,
	cardStylesStructure,
} from './card-styles';

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.less'],
})
export class CardComponent {
	icons = ShieldIcons;
	public github: SVGIcon = ShieldIcons.shGithub;
	public left: SVGIcon = ShieldIcons.shArrowLeft;
	public right: SVGIcon = ShieldIcons.shArrowRight;
	public question: SVGIcon = ShieldIcons.shCircleQuestion;
	public print: SVGIcon = ShieldIcons.shPrint;
	public switchValue = false;
	public disabled = false;

	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public linkIcon = ShieldIcons.shLink;

	// Imported Style Variables
	public stylesDefault = cardStylesDefaultTheme;
	public stylesDark = cardStylesDarkTheme;
	public stylesTypography = cardStylesTypography;
	public stylesStructure = cardStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api'
				}
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Interactions',
					value: 'interactions',
				},
				{
					name: 'Expandable/Resizing',
					value: 'expandable-resizing',
				},
				{
					name: 'Reordering',
					value: 'reordering',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Default',
					value: 'default',
				},
				{
					name: 'Dashboard Card',
					value: 'dashboard-card',
				}
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Data Visualization',
					value: 'data-visualization',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeEnabled(event): void {
		this.disabled = event;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
