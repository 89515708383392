import { Component, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { iconsData } from './icons-data';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import * as ShieldIcons from '@beyondtrust/shield-icons';
import { shFilter, shXmark } from '@beyondtrust/shield-icons';
import _ from 'lodash';
import { ChangelogService } from 'src/app/services/changelog.service';

@Component({
	selector: 'app-icons',
	templateUrl: './icons.component.html',
	styleUrls: ['./icons.component.less'],
})
export class IconsComponent {
	@ViewChild('criteria', { static: false }) criteria: any;
	@ViewChild('SearchInput', { static: false }) SearchInput: any;

	public icons = ShieldIcons;
	public iconNames = Object.keys(this.icons);
	public filterIcon = shFilter;
	public xIcon = shXmark;

	public inputDropDownShown = false;
	public showIconDetail = false;
	public data = iconsData;
	public detailData = {};
	public filteredData = this.data;
	public hideClearIcon = true;
	public inputValue = '';

	public currVisible = 'overview';

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Icon library',
			value: 'icon-library',
			empty: false,
			children: [],
		},
		{
			name: 'Principles',
			value: 'principles',
			empty: false,
			children: [],
		},
		{
			name: 'Icons and text',
			value: 'icons-text',
			empty: false,
			children: [],
		},
		{
			name: 'Icon anatomy',
			value: 'icon-anatomy',
			empty: false,
			children: [],
		},
		{
			name: 'Icon styles',
			value: 'icon-styles',
			empty: false,
			children: [
				{
					name: 'Filled and outlined',
					value: 'filled-outlined',
					empty: false,
					children: [],
				},
				{
					name: 'Color and contrast',
					value: 'color-contrast',
					empty: false,
					children: [],
				},
				{
					name: 'Scale',
					value: 'scale',
					empty: false,
					children: [],
				},
			],
		},
		{
			name: 'Interactive icon states',
			value: 'interactive-icon-states',
			empty: false,
			children: [
				{
					name: 'Hover',
					value: 'hover',
					empty: false,
					children: [],
				},
				{
					name: 'Disabled',
					value: 'disabled',
					empty: false,
					children: [],
				},
			],
		},
		{
			name: 'Designing new icons',
			value: 'designing-new-icons',
			empty: false,
			children: [
				{
					name: 'Grid',
					value: 'grid',
					empty: false,
					children: [],
				},
				{
					name: 'Key shapes',
					value: 'key-shapes',
					empty: false,
					children: [],
				},
				{
					name: 'Icon alignment',
					value: 'icon-alignment',
					empty: false,
					children: [],
				},
				{
					name: 'Gap',
					value: 'gap',
					empty: false,
					children: [],
				},
				{
					name: 'Off icon',
					value: 'off-icon',
					empty: false,
					children: [],
				},
				{
					name: 'Modifiers',
					value: 'modifiers',
					empty: false,
					children: [],
				},
				{
					name: 'Naming new icons',
					value: 'naming-new-icons',
					empty: false,
					children: [],
				},
			],
		},
		{
			name: 'Resources',
			value: 'resources',
			empty: false,
			children: [],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
	public onSearchInput(): void {
		this.inputDropDownShown = true;
		let criteria = this.SearchInput.nativeElement.value.toLocaleLowerCase();
		if (criteria.length >= 1) {
			this.hideClearIcon = false;
			this.filteredData = this.data.filter(
				(searchFilter) =>
					searchFilter.meta.find((meta) =>
						meta.toLocaleLowerCase().includes(criteria)
					) ||
					searchFilter.name.toLocaleLowerCase().includes(criteria) ||
					searchFilter.aliases.find((alias) =>
						alias.toLocaleLowerCase().includes(criteria)
					) ||
					searchFilter.usage.find((usage) =>
						usage.toLocaleLowerCase().includes(criteria)
					)
			);
		} else {
			this.hideClearIcon = true;
			this.filteredData = this.data;
		}
	}
	public clearIconFilter() {
		this.hideClearIcon = true;
		this.filteredData = this.data;
		this.SearchInput.nativeElement.value = '';
	}
	iconLookup(icon: any): ShieldIcons.SVGIcon {
		const tsName = _.camelCase(icon.name);
		return ShieldIcons[tsName as keyof typeof ShieldIcons];
	}

	getIconName(value: string): string {
		let kebabValue = this.iconLookup(value)['name'];
		return _.lowerCase(kebabValue);
	}

	openIconDetail(icon: any) {
		this.showIconDetail = true;
		this.detailData = icon;
	}

	closeIconDetail(b: boolean) {
		if (b) {
			this.showIconDetail = false;
		}
	}
}
