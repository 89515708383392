import { Component, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import {
	tabStylesDefaultTheme,
	tabStylesDarkTheme,
	tabStylesTypography,
	tabStylesStructure,
	verticalTabStylesDefaultTheme,
	verticalTabStylesDarkTheme,
	verticalTabStylesStructure,
} from './tab-styles';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCircleExclamation } from '@beyondtrust/shield-icons';
import { TabPosition, TabStripComponent } from '@progress/kendo-angular-layout';

@Component({
	selector: 'app-tab',
	templateUrl: './tab.component.html',
	styleUrls: ['./tab.component.less'],
})
export class TabComponent {
	@ViewChild('tabs', { read: TabStripComponent, static: true })
	public tabsComponent: TabStripComponent;
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public showWarning = true;
	public error = shCircleExclamation;

	// Imported Style Variables
	public stylesDefault = tabStylesDefaultTheme;
	public verticalStylesDefault = verticalTabStylesDefaultTheme;
	public verticalStylesDark = verticalTabStylesDarkTheme;
	public verticalStylesStructure = verticalTabStylesStructure;
	public stylesDark = tabStylesDarkTheme;
	public stylesTypography = tabStylesTypography;
	public stylesStructure = tabStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				// {
				// 	name: 'API',
				// 	value: 'api',
				// },
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Hover',
					value: 'hover',
				},
				{
					name: 'Active',
					value: 'active',
				},
				{
					name: 'Inactive',
					value: 'inactive',
				},
				{
					name: 'Error (active)',
					value: 'error-active',
				},
				{
					name: 'Error (inactive)',
					value: 'error-inactive',
				},
				{
					name: 'Required fields',
					value: 'required-fields',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Horizontal tabs',
					value: 'horizontal',
				},
				// {
				// 	name: 'Vertical tabs',
				// 	value: 'vertical'
				// },
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Position',
					value: 'position',
				},
				{
					name: 'Accessibility',
					value: 'accessibility',
				},
				{
					name: 'Tabs versus button groups',
					value: 'versus',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	public position: TabPosition = 'left';
	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.tabsComponent.selectTab(0);
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public tabsHTML = `
<kendo-tabstrip #tabs>
	<kendo-tabstrip-tab title="Accounts" [selected]="true">
		<ng-template kendoTabContent>
			<div class="content">
				<span>Privilege, Detections, and Recommendations</span>
			</div>
		</ng-template>
	</kendo-tabstrip-tab>
	<kendo-tabstrip-tab title="Connectors">
		<ng-template kendoTabContent>
			<div class="content">
				<span>Available Connectors</span>
			</div>
		</ng-template>
	</kendo-tabstrip-tab>
	<kendo-tabstrip-tab title="Detections">
		<ng-template kendoTabContent>
			<div class="content">
				<span>Overview</span>
			</div>
		</ng-template>
	</kendo-tabstrip-tab>
	<kendo-tabstrip-tab title="Identities">
		<ng-template kendoTabContent>
			<div class="content">
				<span>Privilege, Detections, and Recommendations</span>
			</div>
		</ng-template>
	</kendo-tabstrip-tab>
	<kendo-tabstrip-tab [class]="error ? 'sh-tab-error' : ''">
		<ng-template kendoTabTitle> <kendo-svg-icon
				[icon]="error"
				*ngIf="error"
				themeColor="error"
			></kendo-svg-icon>Recommendations </ng-template>
		<ng-template kendoTabContent>
			<div class="content">
				<kendo-svg-icon
					[icon]="error"
					themeColor="error"
					class="icon"
					*ngIf="error"
				></kendo-svg-icon>
				<span>Overview</span>
			</div>
		</ng-template>
	</kendo-tabstrip-tab>
</kendo-tabstrip>`;
}
