import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { SVGIcon, shChevronRight } from '@beyondtrust/shield-icons';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';

import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';

// Data Sources
import {
	breadcrumbStylesDefaultTheme,
	breadcrumbStylesDefaultThemeText,
	breadcrumbStylesDarkTheme,
	breadcrumbStylesDarkText,
	breadcrumbStylesTypography,
	breadcrumbStylesTypographyText,
	breadcrumbStylesStructureText,
	breadcrumbStylesStructure
} from './breadcrumb-styles';

const defaultItems: BreadCrumbItem[] = [
	{
		text: 'Home',
		title: 'Home'
	},
	{
		text: 'Security',
		title: 'Security'
	},
	{
		text: 'Users',
		title: 'Users'
	},
	{
		text: 'Add User',
		title: 'Add User'
	}
];

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.less']
})
export class BreadcrumbComponent {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';

	public chevronRight: SVGIcon = shChevronRight;
	public items: BreadCrumbItem[] = [...defaultItems];

	// Imported Style Variables
	public stylesDefault = breadcrumbStylesDefaultTheme;
	public stylesDefaultText = breadcrumbStylesDefaultThemeText;
	public stylesDark = breadcrumbStylesDarkTheme;
	public stylesDarkText = breadcrumbStylesDarkText;
	public stylesTypography = breadcrumbStylesTypography;
	public stylesTypographyText = breadcrumbStylesTypographyText;
	public stylesStructure = breadcrumbStylesStructure;
	public stylesStructureText = breadcrumbStylesStructureText;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: []
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: []
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started'
				},
				{
					name: 'Code examples',
					value: 'code'
				},
				{
					name: 'API',
					value: 'api'
				}
			]
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: []
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Breadcrumb',
					value: 'breadcrumb'
				},
				{
					name: 'Focus',
					value: 'focus'
				}
			]
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: true,
			children: []
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general'
				},
				{
					name: 'Placement',
					value: 'placement'
				},
				{
					name: 'Content',
					value: 'content'
				}
			]
		},
		{
			name: 'Resources',
			value: 'resources',
			empty: false,
			children: []
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: []
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: []
		}
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onItemClick(item: BreadCrumbItem): void {
		const index = this.items.findIndex(e => e.text === item.text);
		this.items = this.items.slice(0, index + 1);
	}

	public refreshBreadCrumb(): void {
		this.items = [...defaultItems];
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url'
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public breadcrumbHTML = `
<kendo-breadcrumb
	[collapseMode]="'wrap'"
	[items]="items"
	[separatorSVGIcon]="chevronRight"
	(itemClick)="onItemClick($event)"
></kendo-breadcrumb>`;
}
