// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const cardStylesDefaultTheme = [];

export const cardStylesDarkTheme = [];

export const cardStylesTypography = [];

export const cardStylesStructure = [];
