export function DeepCopy(o: any): any {
	let out: any;
	let v;

	if (o instanceof Date) {
		out = new Date(o);
	} else {
		out = Array.isArray(o) ? [] : {};

		// tslint:disable-next-line:forin
		for (const key in o) {
			v = o[key];
			out[key] = typeof v === 'object' && v !== null ? DeepCopy(v) : v;
		}
	}
	return out;
}
