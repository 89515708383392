// External Imports
import { Injectable } from '@angular/core';

// Internal Imports
import { ChangelogItem } from '../interfaces/changelog-item';
import { changelogData } from './changelog-data';

@Injectable({
	providedIn: 'root',
})
export class ChangelogService {
	public data = changelogData;
	public sortDate = true;
	public changelogDates = [];
	public changelogSections = [];
	public tempDateArray = [];
	public tempSectionArray = [];

	constructor() {
		// Extract dates and sections (only unique)
		for (var i = 0; i < this.data.length; i++) {
			// Unique dates
			let currentDate = this.data[i].changeDate;
			if (
				this.changelogDates.findIndex(function (x) {
					return x.getTime() === currentDate.getTime();
				}) === -1
			) {
				this.changelogDates.push(this.data[i].changeDate);
			}

			this.tempSectionArray.push(this.data[i].sectionName);
		}

		// Unique sections
		this.changelogSections = [...new Set(this.tempSectionArray)].sort();
	}

	// Formatting dates for display
	public formatDate(changeDate: Date): string {
		return changeDate.toLocaleDateString('en-us', {
			month: 'long',
			day: 'numeric',
			year: 'numeric',
		});
	}

	// Return changelog by section, sorted by date (descending)
	public getChangelogBySection(item: string): Array<ChangelogItem> {
		return this.data
			.filter(
				(data) =>
					data.sectionName === item ||
					data.sectionName === 'All Sections'
			)
			.sort((a, b) => {
				return Number(a.changeDate) - Number(b.changeDate);
			})
			.reverse();
	}

	// Return changelog by date, sorted by section (ascending)
	public getChangelogByDate(item: Date): Array<ChangelogItem> {
		return this.data
			.filter((data) => data.changeDate.getTime() === item.getTime())
			.sort((a, b) => {
				let sa = a.sectionName,
					sb = b.sectionName;

				if (sa < sb) {
					return -1;
				}
				if (sa > sb) {
					return 1;
				}
				return 0;
			});
	}

	// Return changelog by date string, sorted by section (ascending)
	public getChangelogByDateString(item: string): Array<ChangelogItem> {
		let itemDate = new Date(item);
		return this.data
			.filter((data) => data.changeDate.getTime() === itemDate.getTime())
			.sort((a, b) => {
				let sa = a.sectionName,
					sb = b.sectionName;

				if (sa < sb) {
					return -1;
				}
				if (sa > sb) {
					return 1;
				}
				return 0;
			});
	}

	// Return unique dates
	public getChangelogDates(): Array<Date> {
		return this.changelogDates;
	}
}
