// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const paginationStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
];

export const paginationStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
];

export const paginationStylesTypography = [
	{
		name: 'Text and labels',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const paginationStylesStructure = [
	{
		name: 'Container',
		property: 'width',
		value: '100% of parent control',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
];

export const paginationTextFieldStylesStructure = [
	{
		name: 'Container',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-width',
		value: '50px',
		isColorTile: false,
	},
];

export const paginationDropDownStylesStructure = [
	{
		name: 'Container',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-width',
		value: '80px',
		isColorTile: false,
	},
];
