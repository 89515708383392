import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';

import { shCheck, shXmark, shLink } from '@beyondtrust/shield-icons';

// Styles
import {
	buttonStylesDarkThemePrimary,
	buttonStylesDarkThemeSecondary,
	buttonStylesDarkThemeText,
	buttonStylesDefaultThemePrimary,
	buttonStylesDefaultThemeSecondary,
	buttonStylesDefaultThemeText
} from '../button/button-styles';
import {
	timePickerStylesDefaultTheme,
	timePickerStylesDarkTheme,
	timePickerStylesTypography,
	timePickerStylesStructure,
	textFieldStylesDarkTheme,
	textFieldStylesDefaultTheme
} from './time-picker-styles';

import {
	textFieldStylesTypography
} from './../text-field/text-field-styles';

import {
	buttonStylesTypography
} from './../button/button-styles';

@Component({
	selector: 'app-time-picker',
	templateUrl: './time-picker.component.html',
	styleUrls: ['./time-picker.component.less']
})
export class TimePickerComponent {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';

	//Shield icons
	public checkIcon = shCheck;
	public linkIcon = shLink;
	public xMarkIcon = shXmark;

	//form variables
	public disabled = false;
	public timeValue: Date = new Date();
	public form: FormGroup = new FormGroup({
		timeOfDay: new FormControl(this.timeValue, Validators.required)
	});

	// Imported Style Variables
	public stylesDefault = timePickerStylesDefaultTheme;
	public stylesDark = timePickerStylesDarkTheme;
	public stylesTypography = timePickerStylesTypography;
	public stylesStructure = timePickerStylesStructure;

	//Button Styles
	public buttonStylesDarkPrimary = buttonStylesDarkThemePrimary;
	public buttonStylesDarkSecondary = buttonStylesDarkThemeSecondary;
	public buttonStylesDarkText = buttonStylesDarkThemeText;
	public buttonStylesDefaultPrimary = buttonStylesDefaultThemePrimary;
	public buttonStylesDefaultSecondary = buttonStylesDefaultThemeSecondary;
	public buttonStylesDefaultText = buttonStylesDefaultThemeText;
	public stylesButtonTextTypography = buttonStylesTypography;

	//Text Field Styles
	public textFieldStylesDefault = textFieldStylesDefaultTheme;
	public textFieldStylesDark = textFieldStylesDarkTheme;
	public stylesTextFieldTypography = textFieldStylesTypography;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: []
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: []
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code'
				},
				{
					name: 'API',
					value: 'api'
				}
			]
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: []
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Focus',
					value: 'focus'
				},
				{
					name: 'Text field',
					value: 'text-field'
				},
				{
					name: 'Clock pop-up',
					value: 'clock-popup'
				},
				{
					name: 'Input masking',
					value: 'masking'
				},
				{
					name: 'Error',
					value: 'error'
				},
				{
					name: 'Disabled',
					value: 'disabled'
				},
				{
					name: 'Help text',
					value: 'help-text'
				},
				{
					name: 'Instructional text',
					value: 'instructional-text'
				}
			]
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: true,
			children: []
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Time format',
					value: 'time-format'
				},
				{
					name: 'Content',
					value: 'content'
				}
			]
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: []
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: []
		}
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public get timeControl(): AbstractControl {
		return this.form.controls.timeOfDay;
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
		switch (this.currLive) {
			case 'disabled':
				this.disabled = true;
				break;
			default:
				this.disabled = false;
				break;
		}
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url'
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public timePickerHTML = `
<kendo-formfield>
	<kendo-label
		[for]="timeOfDay"
		text="Time of Day"
	>
	</kendo-label>
	<kendo-timepicker
		#timeOfDay
		formControlName="timeOfDay"
		[disabled]="disabled" 
		[incompleteDateValidation]="true"
		[(ngModel)]="timeValue"
	></kendo-timepicker>
	<kendo-formhint>HH:MM AM/PM</kendo-formhint>
	<kendo-formerror *ngIf="timeValue === null && !timeControl.errors?.incompleteDate">
		Time of Day is required.
	</kendo-formerror>
	<kendo-formerror *ngIf="timeControl.errors?.incompleteDate">
		Time of Day must not be incomplete.
	</kendo-formerror>
</kendo-formfield>`;

	public timePickerTS = `
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';	
public timeValue = new Date();
public form: FormGroup = new FormGroup({
	timeOfDay: new FormControl(this.timeValue, Validators.required),
});
public disabled = false;
public get timeControl(): AbstractControl {
	return this.form.controls.timeOfDay;
}

`;
}
