import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCheck, shXmark } from '@beyondtrust/shield-icons';
import * as ShieldIcons from '@beyondtrust/shield-icons';
import { SVGIcon } from '@beyondtrust/shield-icons';

// Data Sources
import {
	progressIndicatorStylesDefaultTheme,
	progressIndicatorStylesDarkTheme,
	progressIndicatorStylesCircleTypography,
	progressIndicatorStylesLineStructure,
	progressIndicatorStylesCircleStructure,
} from './progress-indicator-styles';

@Component({
	selector: 'app-progress-indicator',
	templateUrl: './progress-indicator.component.html',
	styleUrls: ['./progress-indicator.component.less'],
})
export class ProgressIndicatorComponent {
	public currLive = 'determinate';
	public currTheme = 'default';
	public currVisible = 'overview';

	public value = 30;
	icons = ShieldIcons;
	public circleSlash: SVGIcon = ShieldIcons.shCircleSlash;
	public circleCheck: SVGIcon = ShieldIcons.shCircleCheck;

	// Imported Style Variables
	public stylesDefault = progressIndicatorStylesDefaultTheme;
	public stylesDark = progressIndicatorStylesDarkTheme;
	public stylesCircleTypography = progressIndicatorStylesCircleTypography;
	public stylesLineStructure = progressIndicatorStylesLineStructure;
	public stylesCircleStructure = progressIndicatorStylesCircleStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Animation',
					value: 'animation',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Determinate line',
					value: 'line',
				},
				{
					name: 'Indeterminate line',
					value: 'repeating-line',
				},
				{
					name: 'Indeterminate circle',
					value: 'circle',
				},
				{
					name: 'Reverse determinate line',
					value: 'reverse-line',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Progress indicators with buttons',
					value: 'with-buttons',
				},
				{
					name: 'Placement',
					value: 'placement',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	public determinateValue = 1;
	public reverseDeterminateValue = 100;
	public running;

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public startProgress(): void {
		if (this.currLive === 'determinate') {
			this.running = setInterval(() => {
				if (this.determinateValue < 100) {
					this.determinateValue++;
				} else {
					this.stopProgress();
				}
			}, 50);
		} else if (this.currLive === 'reverse-determinate') {
			this.running = setInterval(() => {
				if (this.reverseDeterminateValue > 0) {
					this.reverseDeterminateValue--;
				} else {
					this.stopProgress();
				}
			}, 50);
		}
	}

	public stopProgress(): void {
		if (this.running) {
			clearInterval(this.running);
			this.running = null;
		}
	}

	public resetProgress(): void {
		this.determinateValue = 1;
		this.reverseDeterminateValue = 100;
		this.stopProgress();
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.resetProgress();
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public progressIndicatorHTML = `
<kendo-progressbar [value]="value"></kendo-progressbar>

<kendo-progressbar [indeterminate]="true"></kendo-progressbar>

<kendo-circularprogressbar style="width: 44px; height: 44px"  [indeterminate]="true" [value]="50"></kendo-circularprogressbar>

<kendo-circularprogressbar style="width: 68px; height: 68px"  [indeterminate]="true" [value]="50"></kendo-circularprogressbar>

<button kendoButton fillMode="none" class="sh-button-with-progress">
   <kendo-progressbar [indeterminate]="true" [value]="20"></kendo-progressbar>
   <div class="progress-button-text">Primary</div>
</button>
`;
}
