<div class="icon-detail-overlay" (click)="onClose()"></div>
<div class="icon-detail-container">
	<div class="icon-detail-header">
		<span class="icon-detail-header-label">{{ iconData.name }} Details</span>
		<span class="icon-detail-header-close custom24"><kendo-svg-icon [icon]="icons.shXmark" (click)="onClose()"
				name="Close" title="Close"></kendo-svg-icon></span>
	</div>
	<div class="icon-detail-content">
		<div class="icon-detail-content-demo">
			<span class="icon-section-label">Demo</span>
			<div class="icon-detail-content-demo-icon">
				<kendo-svg-icon [icon]="iconLookup(iconData)" [class.custom48]="size == 'demoSize48'"
					[class.custom24]="size == 'demoSize24'" [class.custom16]="size == 'demoSize16'"></kendo-svg-icon>
			</div>
			<div class="icon-detail-content-demo-options">
				<div class="icon-detail-content-demo-options-row">
					<label for="demoSize">Size:</label>
					<select #demoSize id="demoSize" (change)="onSizeSelected()">
						<option value="demoSize48">48px by 48px</option>
						<option value="demoSize24">24px by 24px</option>
						<option value="demoSize16">16x by 16px</option>
					</select>
				</div>
				<!-- <div class="icon-detail-content-demo-options-row">
					<label for="demoBg">Theme - Color:</label>
					<select id="demoBg">
						<option value="demoBgWhite">Light - White</option>
						<option value="demoBgAsh50">Light - Ash 50%</option>
						<option value="demoBgNavy500">Dark - Navy 500</option>
						<option value="demoBgNavy600">Dark - Navy 600</option>
						<option value="demoBgNavy700">Dark - Navy 700</option>
					</select>
				</div> -->
			</div>
		</div>
		<div class="icon-detail-content-info">
			<span class="icon-section-label">Information</span>
			<table class="icon-detail-content-info-table">
				<tr class="icon-detail-content-info-table-row">
					<td class="icon-detail-content-info-table-cell info-label">
						Name:
					</td>
					<td class="icon-detail-content-info-table-cell info-value">
						{{ iconData.name }}
					</td>
					<td class="icon-detail-content-info-table-cell info-value">
						<kendo-svg-icon class="copy-icon" [icon]="icons.shCopy"
							(click)="copyContentToClipboard(iconData.name)" title="Copy Name to Clipboard"
							name="nameCopy"></kendo-svg-icon>
					</td>
				</tr>
				<tr class="icon-detail-content-info-table-row">
					<td class="icon-detail-content-info-table-cell info-label">
						Uses:
					</td>
					<td class="icon-detail-content-info-table-cell info-value" colspan="2">
						<ul>
							<li *ngFor="let item of iconData.usage">
								{{ item }}
							</li>
						</ul>
					</td>
				</tr>
			</table>
			<span class="icon-section-label">Resources</span>
			<div class="icon-detail-content-resource-link">
				<a [attr.href]="iconData.figma" target="_blank"><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 200 300" width="20" height="20">
						<style type="text/css">
							.st0 {
								fill: #0acf83;
							}

							.st1 {
								fill: #a259ff;
							}

							.st2 {
								fill: #f24e1e;
							}

							.st3 {
								fill: #ff7262;
							}

							.st4 {
								fill: #1abcfe;
							}
						</style>
						<title>Figma.logo</title>
						<desc>Created using Figma</desc>
						<path id="path0_fill" class="st0"
							d="M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z" />
						<path id="path1_fill" class="st1"
							d="M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z" />
						<path id="path1_fill_1_" class="st2"
							d="M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z" />
						<path id="path2_fill" class="st3" d="M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50V0z" />
						<path id="path3_fill" class="st4"
							d="M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z" />
					</svg>Figma</a>
			</div>
		</div>
	</div>
	<div class="icon-detail-content-code">
		<span class="icon-section-label">Code Example
			<kendo-svg-icon class="copy-icon" [icon]="icons.shCopy" (click)="copyExampleToClipboard(getCodeString())"
				title="Copy Code Example to Clipboard" name="nameCopy"></kendo-svg-icon></span>
		<pre class="page-pre-code">
            <span class="code-tag">&lt;kendo-svg-icon</span> <span class="code-var"> [icon]=</span><span class="code-text">"icons.{{getIconName(iconData.name)}}"</span><span class="code-tag">&gt;</span><span class="code-tag">&lt;/kendo-svg-icon&gt;</span>
        </pre>
	</div>

	<div class="icon-detail-footer" *ngIf="iconData.aliases.length !== 0">
		<ul class="icon-detail-footer-alias-list">
			<li class="icon-detail-footer-alias-list-item">
				<span class="icon-section-label">Aliases</span>
			</li>
			<li class="icon-detail-footer-alias-list-item" *ngFor="let item of iconData.aliases">
				{{ item }}
			</li>
		</ul>
	</div>
</div>