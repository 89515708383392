// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: switchStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const switchStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'C8CDD1',
		isColorTile: true,
	},
	{
		name: 'Indicator',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Indicator (on position)',
		property: 'background-color',
		value: '006E18',
		isColorTile: true,
	},
	{
		name: 'Checkmark or minus',
		property: 'border-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'FFBB99',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
];
export const switchStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Indicator',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Indicator (on position)',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Checkmark or minus',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];
export const switchStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];
export const switchStylesStructure = [
	{
		name: 'Container (excluding value)',
		property: 'border-radius',
		value: '50% (evaluated at 9999px)',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '44px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '22px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Indicator',
		property: 'width',
		value: '22px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '22px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: 'Indicator icon',
		property: 'width',
		value: '13px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '13px',
		isColorTile: false,
	},
	{
		name: 'Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const switchStylesDefaultThemeOld = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'C8CDD1',
		isColorTile: true,
	},
	{
		name: 'Indicator',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Indicator (On Position)',
		property: 'background-color',
		value: '006E18',
		isColorTile: true,
	},
	{
		name: 'Checkmark / Minus',
		property: 'border-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'FFBB99',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const switchStylesDarkThemeOld = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Indicator',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Indicator (On Position)',
		property: 'background-color',
		value: '7FC5A2',
		isColorTile: true,
	},
	{
		name: 'Checkmark / Minus',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const switchStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '14px',
		isColorTile: false,
	},
];

export const switchStylesStructureOld = [];
