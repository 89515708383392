// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: dropDownStylesStructureWide or buttonStylesDarkThemePrimary

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const dropDownStylesDefaultTheme = [
	{
		name: 'Background',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Item Row Background',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Item Row Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Item Row Background (Selected)',
		property: 'background-color',
		value: 'D3F0F7',
		isColorTile: true,
	},
	{
		name: 'Item Row Text(Selected)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Item Row Background: hover',
		property: 'background-color',
		value: 'FFE6D9',
		isColorTile: true,
	},
];

export const dropDownStylesDarkTheme = [
	{
		name: 'Background',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Item Row Background',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Item Row Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Item Row Background (Selected)',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Item Row Text(Selected)',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Item Row Background: hover',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
];

export const dropDownStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const dropDownStylesStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: '240px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '400px',
		isColorTile: false,
	},
	{
		name: 'Container (area before first item row)',
		property: 'padding-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Container (area after last item row)',
		property: 'padding-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Item Row',
		property: 'height',
		value: '48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-up',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-down',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '48px',
		isColorTile: false,
	},
];
