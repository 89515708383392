import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
	selector: 'app-contribute',
	templateUrl: './contribute.component.html',
	styleUrls: ['./contribute.component.less'],
})
export class ContributeComponent {
	constructor(private viewportScroller: ViewportScroller) {}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}
}
