export const searchData = [
	{
		name: 'General',
		route: 'general',
		meta: '',
	},
	{
		name: 'Changelog',
		route: 'general/changelog',
		meta: 'revision history',
	},
	{
		name: 'FAQ',
		route: 'general/faq',
		meta: 'frequently asked questions',
	},
	{
		name: 'Glossary',
		route: 'general/glossary',
		meta: 'terms, glossary, Address Group, Dialog vs. Dialogue, Drop-down, Email, Log In vs. Login, Log Out vs. Logout, Organization, Smart Group, Smart Rule, Username vs. User Name, Workgroup',
		description:
			'Commonly used terminology found throughout our products, and how to use them.',
	},
	{
		name: 'Contribute to Shield',
		route: 'general/contribute',
		meta: 'contribute, submit, jira',
	},
	{
		name: 'Contact Us',
		route: 'general/contact',
		meta: 'contact, feedback',
	},
	{
		name: 'Overview',
		route: 'guidelines/overview',
		meta: 'recommendation, instruction, direction, suggestion, advice, regulation',
	},
	{
		name: 'Accessibility',
		route: 'guidelines/accessibility',
		meta: 'accessibility, compliance, contrast, 508, guidelines, wcag, a11y',
		description:
			'Accessibility is the practice of removing barriers. Accessibility makes our products as usable to as many people as possible.',
	},
	// {
	// 	name: 'Branding',
	// 	route: 'guidelines/branding',
	// 	meta: 'bt-logo, branding, guidelines, logos, products',
	// 	description:
	// 		"A visual branding guide for BeyondTrust's suite of products.",
	// },
	// {
	// 	name: 'Colors',
	// 	route: 'guidelines/colors',
	// 	meta: 'theme, hue, shade, tint, tone, tinge, cast, tincture, guidelines',
	// },
	{
		name: 'Content',
		route: 'guidelines/content',
		meta: 'writing, content, messaging, notifications, microcopy, guidelines',
		description:
			'General best practices for writing content in and about BeyondTrust products. For copy guidance about a specific component or pattern, check the usage guidelines on each page.',
	},
	{
		name: 'Data Visualization',
		route: 'guidelines/data-visualization',
		meta: 'charts, graphs, charting, bar chart, line chart, doughnut chart, donut, data visualization, pie chart, guidelines',
		description:
			"Data visualization, or charting, shows information in a graph. We use data visualization to display complex and dense data in a way that's easy for users to understand. Data visualization helps users see patterns, answer questions, and make decisions.",
	},
	{
		name: 'Formatting',
		route: 'guidelines/formatting',
		meta: 'formatting, date, time, decimals, localization, regional, guidelines',
		description:
			'Formatting is how we structure and display different types of data, like date and time.',
	},
	{
		name: 'Icons',
		route: 'guidelines/icons',
		meta: 'icons, font-awesome, svg, library, guidelines',
		description:
			'Icons are small symbols that represent actions and items. Icons help users complete tasks by giving them visual cues. Icons are different from illustrations, which are larger and more detailed.',
	},
	{
		name: 'Motion',
		route: 'guidelines/motion',
		meta: 'motion, animation, transition, guidelines, 250ms',
		description:
			'Motion, or animation, is the speed at which UI elements change.',
	},
	{
		name: 'Severity',
		route: 'guidelines/severity',
		meta: 'severity, high, low, warning, critical, guidelines',
		description:
			'Severity is the framework for scoring and differentiating the	different degrees of risk. We use color, words, and components to communicate severity.',
	},
	{
		name: 'Spacing',
		route: 'guidelines/spacing',
		meta: 'padding, margins, gaps, white-space, spacing, guidelines',
		description:
			'Padding is the space between elements on a UI. Spacing is the space between two containers in a layout. Together, padding and spacing help create clear, functional layouts.',
	},
	{
		name: 'Typography',
		route: 'guidelines/typography',
		meta: 'fonts, typography, h-tag, semantic, subtitle, sub-title, line length, alignment, baseline, open sans, fira code, typeface, error, instructional, h1, h2, h3, h4, hierarchy, supporting, billboard, title, labels, font-family, guidelines',
		description:
			"Typography is the style and appearance of text. We use typography to present content in a way that's legible, scannable, and nice to look at. Consistent typography establishes patterns and hierarchy that help guide our users through products.",
	},
	{
		name: 'Overview',
		route: 'components/overview',
		meta: 'components, common, part, piece, bit, constituent, element, ingredient, unit',
	},
	{
		name: 'Accordion',
		description:
			'Accordions are stacked headers that allow users to hide or view smaller blocks of content. Accordions are known as ExpansionPanels in Kendo.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/layout/expansionpanel/',
		codeLink: '',
		route: 'components/accordion',
		meta: 'accordion, expansion panel, arrow toggle, collapse, collapsible sections, details, drawer, expander, component, collapsing, drawer, open, close, hide, hidden, collapsible, toggle, chevron, arrow',
		otherNames:
			'Collapsible Panel, Collapsible Section, Expander, Expansion Panel',
	},
	// {
	// 	name: 'Banner',
	// 	description:
	// 		'Banners communicate messages to users which they can act on or dismiss. Use banners to prominently display important information.',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'components/banner',
	// 	meta: 'banner, messaging, error, notification, alert, component',
	// 	otherNames: 'Alert',
	// },
	{
		name: 'Bar Chart',
		description:
			'Bar Charts display data to users using bars of different heights.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/charts/series-types/bar/',
		codeLink: '',
		route: 'components/bar-chart',
		meta: 'charting, chart, data visualization, bar chart, bar graph, histogram, column graph, component',
		otherNames: 'Histogram, Column Graph',
	},
	{
		name: 'Breadcrumb',
		description:
			'Breadcrumbs are both way finding and navigation elements. They are presented as text links reflecting the user’s current location and place in the application’s information hierarchy. Breadcrumbs help users understand where they are in an application and allow them to move back up the hierarchical structure of the application.',
		designLink: '',
		kendoLink: '',
		codeLink: '',
		route: 'components/breadcrumb',
		meta: 'breadcrumb, breadcrumb trail, component',
		otherNames: 'Breadcrumb Trail',
	},
	{
		name: 'Button',
		description:
			'Buttons tell users what actions are available and allow them to perform those actions.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/buttons/api/',
		route: 'components/button',
		codeLink: '',
		meta: 'buttons, push-button, selector, component',
	},
	{
		name: 'Button Group',
		description:
			'Button groups let users perform similar actions from a collection of buttons.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/buttons/buttongroup/',
		codeLink: '',
		route: 'components/button-group',
		meta: 'Button Group, button-group, toolbar, button bar, component',
		otherNames: 'Toolbar, Button Bar',
	},
	{
		name: 'Checkbox',
		description:
			'Checkboxes allow a user to select one or more options. Checkboxes inform users about the state of possible configurations and customizations.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/inputs/checkbox/',
		codeLink: '',
		route: 'components/checkbox',
		meta: 'checkbox, check box, check-box, component',
	},
	// {
	// 	name: 'Combo Box',
	// 	description:
	// 		'Combo Boxes let a user select one or multiple options from a, possibly searchable, list.',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'components/combo-box',
	// 	meta: 'combo-box, component, autocomplete, auto-complete, auto complete, autosuggest, auto suggest, listbox, list box, datalist, data list',
	// 	otherNames: 'Autocomplete, Autosuggest, List Box, Data List',
	// },
	{
		name: 'Data Grid',
		description:
			'Data Grids let a user interact with tabular data and perform actions on that data.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/grid/api/',
		codeLink: '',
		route: 'components/data-grid',
		meta: 'data-grid, data-table, table, basic table, data grid, component, grid',
		otherNames: 'Grid, Data table',
	},
	{
		name: 'Date Picker',
		description:
			'Date pickers let a user enter a date or select one from the calendar pop-up.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/dateinputs/datepicker/',
		codeLink: '',
		route: 'components/date-picker',
		meta: 'time, date, calendar, datetime picker, date input, component',
		otherNames: 'Calendar, Date Input',
	},
	{
		name: 'Donut Chart',
		description:
			'A donut chart is a pie chart with a "hole" in the center. The chart is divided into parts that show the percentages from the data.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/charts/series-types/donut/',
		codeLink: '',
		route: 'components/donut-chart',
		meta: 'charting, chart, data visualization, donut chart, doughnut chart, doughnut, donut, component',
		otherNames: 'Doughnut Chart',
	},
	// {
	// 	name: 'Drop-down',
	// 	description:
	// 		'Drop-downs present a list of options that users can select from.',
	// 	designLink: '',
	// 	kendoLink:
	// 		'https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/',
	// 	codeLink: '',
	// 	route: 'components/drop-down',
	// 	meta: 'pull down, submenu, picklist, datalist, data list, menubar, drop-down, sub-menu, scroll-down, component',
	// 	otherNames: 'Data List, Single Select Menu',
	// },
	// {
	// 	name: 'File Upload',
	// 	description:
	// 		'File uploads let a user attach one or more selected files to a form.',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'components/file-upload',
	// 	meta: 'file-upload, file input, file upload, uploader, dropzone, drop zone, attachment, component',
	// 	otherNames: 'File Input, Uploader, Drop Zone',
	// },
	{
		name: 'Help Text',
		description:
			'Help text allows users to see expanded information in a floating message box.',
		designLink: '',
		kendoLink: '',
		codeLink: '',
		route: 'components/help-text',
		meta: 'helptext, helper, help text, component, popover, pop-over',
		otherNames: 'Popover',
	},
	{
		name: 'Icon',
		description: 'Icons are symbols that represent actions and items.',
		designLink: '',
		kendoLink: 'https://www.telerik.com/kendo-angular-ui/components/icons/',
		codeLink: '',
		route: 'components/icon',
		meta: 'icon, component',
	},
	{
		name: 'Label / Value Pair',
		description:
			'Label/Value pairs let a user view read-only, labelled information.',
		designLink: '',
		kendoLink: '',
		codeLink: '',
		route: 'components/label-value',
		meta: 'label-value, label value pair, definition list, components',
		otherNames: 'Definition List',
	},
	// {
	// 	name: 'Line Chart',
	// 	description: 'Line charts use lines to connect data points on a graph.',
	// 	designLink: '',
	// 	kendoLink:
	// 		'https://www.telerik.com/kendo-angular-ui/components/charts/series-types/line/',
	// 	codeLink: '',
	// 	route: 'components/line-chart',
	// 	meta: 'charting, chart, data visualization, line graph, line plot, plot, line chart, component',
	// 	otherNames: 'Line Graph, Line Plot',
	// },
	// {
	// 	name: 'List',
	// 	description:
	// 		'Lists allow a user to select one or multiple items and perform an action on them.',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'components/list',
	// 	meta: 'list, list-box, list box, component',
	// 	otherNames: 'List Box',
	// },
	{
		name: 'Menu',
		description:
			'Menus display a list of choices that allow users to perform an action. Menus appear when users interact with a control.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/menus/menu/',
		codeLink: '',
		route: 'components/menu',
		meta: 'menu, list, component, popup',
		otherNames: 'Context Menu, Grid Menu',
	},
	{
		name: 'Modal',
		description:
			'Modals are pop-ups that require users to choose an action before they can continue.',
		designLink: '',
		kendoLink: '',
		codeLink: '',
		route: 'components/modal',
		meta: 'modal, dialog, pop-up, popup, modal window, dialog, component',
		otherNames: 'Dialog, Pop-up, Modal Window',
	},
	{
		name: 'Number Field',
		description:
			'Number fields allow users to enter a numeric value and incrementally increase or decrease that value.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/inputs/numerictextbox/',
		codeLink: '',
		route: 'components/number-field',
		meta: 'number-field, number, numeric, stepper, component',
		otherNames: 'Numeric, Stepper',
	},
	// {
	// 	name: 'Pagination',
	// 	description: 'Pagination allows users to navigate between large amounts of content that’s been split into pages.',
	// 	designLink: '',
	// 	kendoLink: 'https://www.telerik.com/kendo-angular-ui/components/pager/',
	// 	codeLink: '',
	// 	route: 'components/pagination',
	// 	meta: 'pagination, component',
	// },
	// {
	// 	name: 'Password Field',
	// 	description:
	// 		'Password fields allow a user to securely enter a password and can give helpful guidance when creating one.',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'components/password-field',
	// 	meta: 'password, password field, login, creds, input, component',
	// },
	{
		name: 'Pie Chart',
		description:
			'A pie chart shows counts or percentages as sections of a circle.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/charts/series-types/pie/',
		codeLink: '',
		route: 'components/pie-chart',
		meta: 'charting, chart, data visualization, pie chart, circle chart, circle graph, pie graph, component',
		otherNames: 'Circle Chart',
	},
	// {
	// 	name: 'Popup',
	// 	description:
	// 		'Popups display on top of other content and are triggered by clicking on an element. ',
	// 	designLink: '',
	// 	kendoLink: 'https://www.telerik.com/kendo-angular-ui/components/popup/',
	// 	codeLink: '',
	// 	route: 'components/popup',
	// 	meta: 'popup, pop-up, popups, pop-ups, profile, dialog, component',
	// },
	{
		name: 'Progress Indicator',
		description:
			'Progress indicators visualize the status of an action. Use progress indicators to show users how much of the process has been completed, and how much is still left to go.',
		designLink: '',
		kendoLink: '',
		codeLink: '',
		route: 'components/progress-indicator',
		meta: 'progress-indicator, progress, loading, spinner, component',
		otherNames:
			'Loading, Spinner, Determinate, Indeterminate, Progress Bar',
	},
	{
		name: 'Radio Button',
		description:
			'Radio buttons let a user pick one choice from a list of options.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/inputs/radiobutton/',
		codeLink: '',
		route: 'components/radio-button',
		meta: 'radio-button, radio, radio group, component',
		otherNames: 'Radio, Radio Group',
	},
	// {
	// 	name: 'Range Slider',
	// 	description:
	// 		'Range Sliders provide users with a way to pick a range of options between two values.',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'components/range-slider',
	// 	meta: 'range-slider, range slider, range input, component',
	// 	otherNames: 'Range Input',
	// },
	{
		name: 'Severity',
		description:
			'Severity “dots” indicate levels to a user to help them assess and understand risks. Though called severity, the dots can also be used to display privilege level and other data that can be scored.',
		designLink: '',
		kendoLink: '',
		codeLink: '',
		route: 'components/severity',
		meta: 'severity, dots, indicator, high, moderate, critical, low, component',
	},
	// {
	// 	name: 'Slider',
	// 	description:
	// 		'Sliders provide users with a way to pick an option on a range between two values.',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'components/slider',
	// 	meta: 'slider, input, component',
	// },
	{
		name: 'Switch',
		description:
			'Switches let users adjust settings by toggling between two states. Toggling a switch immediately performs an action.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/inputs/switch/',
		codeLink: '',
		route: 'components/switch',
		meta: 'switch, component, toggle',
		otherNames: 'Toggle',
	},
	{
		name: 'Tab',
		description:
			'Tabs allow users to navigate through related content. Use tabs to organize information within the same context.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/layout/tabstrip/',
		codeLink: '',
		route: 'components/tab',
		meta: 'tab, tabbed interface, tab group, tabs, tab bar, component',
		otherNames: 'Tabbed Interface, Tab Group, Tab Bar',
	},
	{
		name: 'Tag',
		description:
			'Tags allow users to identify a label or category for an item.  They can be used to represent things like a filter, severity level, or grouping.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/buttons/chip/',
		codeLink: '',
		route: 'components/tag',
		meta: 'tag, component',
	},
	{
		name: 'Text Field',
		description:
			'Text fields are form controls that allow users to enter text.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/inputs/textbox/',
		codeLink: '',
		route: 'components/text-field',
		meta: 'text-field, text field, textbox, text box, input field, form input, input, textfield, component',
		otherNames: 'Textbox, input field, text input',
	},
	{
		name: 'Text Link',
		description:
			'Text links take users to another place in or outside of the application. Text links act as references or directions to an external or internal resource.',
		designLink: '',
		kendoLink: '',
		codeLink: '',
		route: 'components/text-link',
		meta: 'text-link, component, link, anchor, hyperlink',
		otherNames: 'Link, Anchor, Hyperlink',
	},
	{
		name: 'Time Picker',
		description:
			'Time pickers let a user enter a time or select one from a drop-down.',
		designLink: '',
		kendoLink:
			'https://www.telerik.com/kendo-angular-ui/components/dateinputs/timepicker/',
		codeLink: '',
		route: 'components/time-picker',
		meta: 'time picker, datetime, time input, component',
	},
	// {
	// 	name: 'Timeline',
	// 	description:
	// 		'Allows a user to review a series of events in an application.',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'components/timeline',
	// 	meta: 'timeline, component',
	// },
	{
		name: 'Toast',
		description:
			'Toast messages provide users with short messages about an action they’ve just performed.',
		designLink: '',
		kendoLink: 'https://www.telerik.com/kendo-angular-ui/notification',
		codeLink: '',
		route: 'components/toast',
		meta: 'toast, snackbar, notification, toast notification, messaging, component',
		otherNames: 'Snackbar, Toast Notification, Pop-ups',
	},
	{
		name: 'Tooltip',
		description:
			'Tooltips give users extra information about an element on the UI. Tooltips display a brief description about the element’s purpose and function when users hover or focus on it.',
		designLink: '',
		kendoLink: '',
		codeLink: '',
		route: 'components/tooltip',
		meta: 'tool tip, tooltip, callout, popover, tool-tip, component',
		otherNames: 'Popover, Callout',
	},
	{
		name: 'Overview',
		route: 'patterns/overview',
		meta: 'patterns, molecules',
	},

	// {
	// 	name: 'Application Header',
	// 	description: '',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'patterns/application-header',
	// 	meta: 'header, application header, patterns',
	// },

	// {
	// 	name: 'Application Menu',
	// 	description: '',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'patterns/application-menu',
	// 	meta: 'menu, application menu, patterns',
	// },
	// {
	// 	name: 'Card',
	// 	description:
	// 		'Cards display information and actions that are related to a single concept. We use the Kendo card component — except for dashboard cards, where we implement the Kendo tile layout.',
	// 	designLink: '',
	// 	kendoLink:
	// 		'https://www.telerik.com/kendo-angular-ui/components/layout/card/',
	// 	codeLink: '',
	// 	route: 'patterns/card',
	// 	meta: 'cards, dashboard cards, tiles, patterns',
	// },
	// {
	// 	name: 'Dashboard',
	// 	description: '',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'patterns/dashboard',
	// 	meta: 'dashboard, patterns',
	// },
	// {
	// 	name: 'Empty state',
	// 	description: '',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'patterns/empty-state',
	// 	meta: 'empty-state, patterns',
	// },
	// {
	// 	name: 'Error Page',
	// 	description: '',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'patterns/error-page',
	// 	meta: 'error-page, patterns',
	// },
	{
		name: 'Filter',
		description:
			'Filters add or remove items from a dataset based on a certain set of criteria. Filters help users find information by narrowing down their options.',
		designLink: '',
		kendoLink: 'https://www.telerik.com/kendo-angular-ui/filter',
		codeLink: '',
		route: 'patterns/filter',
		meta: 'filter, patterns',
	},
	// {
	// 	name: 'Form',
	// 	description: '',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'patterns/form',
	// 	meta: 'form, patterns',
	// },
	// {
	// 	name: 'Login',
	// 	description: '',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'patterns/login',
	// 	meta: 'logon, log-in, log-on, logout, sign-in, username, self-registration, patterns',
	// },
	// {
	// 	name: 'Notification',
	// 	description: '',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'patterns/notification',
	// 	meta: 'notification, patterns',
	// },
	// {
	// 	name: 'Panel',
	// 	description: '',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'patterns/panel',
	// 	meta: 'panel, patterns',
	// },
	// {
	// 	name: 'Tree Browser',
	// 	description: '',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'patterns/tree-browser',
	// 	meta: 'tree, tree browser, patterns',
	// },
	// {
	// 	name: 'Wizard',
	// 	description: '',
	// 	designLink: '',
	// 	kendoLink: '',
	// 	codeLink: '',
	// 	route: 'patterns/wizard',
	// 	meta: 'wizard, patterns',
	// },
];
