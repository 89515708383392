import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { searchData } from '../common/search/search-data';

@Component({
	selector: 'app-patterns',
	templateUrl: './patterns.component.html',
	styleUrls: ['./patterns.component.less'],
})
export class PatternsComponent {
	public navData = searchData;

	constructor(private router: Router) {}

	public filterItems(arr, section: string): any[] {
		return arr.filter(
			(x) =>
				x.route.indexOf(section + '/') !== -1 && x.name !== 'Overview'
		);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}
}
