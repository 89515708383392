// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: dashboardStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const dashboardStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const dashboardStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const dashboardStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const dashboardStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const dashboardStylesDefaultThemeOld = [
	{
		name: 'Card Background',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Card Border',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Card Background: hover',
		property: 'background-color',
		value: 'FFE6D9',
		isColorTile: true,
	},
	{
		name: 'Card Border: hover',
		property: 'border-color',
		value: 'FFBB99',
		isColorTile: true,
	},
];

export const dashboardStylesDarkThemeOld = [
	{
		name: 'Card Background',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Card Border',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Card Background: hover',
		property: 'background-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: 'Card Border: hover',
		property: 'border-color',
		value: 'FFE6D9',
		isColorTile: true,
	},
];

export const dashboardStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Header Text',
		property: 'font-family',
		value: '"Open Sans Bold", Arial, sans=serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '20px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
];

export const dashboardStylesStructureOld = [
	{
		name: 'Card',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '230px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding',
		value: '16px',
		isColorTile: false,
	},
];
