// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: sliderStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const sliderStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const sliderStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const sliderStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const sliderStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const sliderStylesDefaultThemeOld = [
	{
		name: 'Bar Background',
		property: 'background-color',
		value: 'E9EBEC',
		isColorTile: true,
	},
	{
		name: 'Bar Border',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Handle Background',
		property: 'background-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Input Background',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Input Border',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Label Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input Value Text',
		property: 'color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Error Border',
		property: 'border-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Error Text',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Handle Background: hover',
		property: 'background-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: 'Dashed Border: focus',
		property: 'border-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Handle Background: focus/active',
		property: 'background-color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: 'Input Border: focus/active',
		property: 'border-color',
		value: '089BBE',
		isColorTile: true,
	},
];

export const sliderStylesDarkThemeOld = [
	{
		name: 'Bar Background',
		property: 'background-color',
		value: '515F6B',
		isColorTile: true,
	},
	{
		name: 'Bar Border',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Handle Background',
		property: 'background-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Input Background',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Input Border',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Label Text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Input Value Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Error Border',
		property: 'border-color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Error Text',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Handle Background: hover',
		property: 'background-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Dashed Border: focus',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Handle Background: focus/active',
		property: 'background-color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: 'Input Border: focus/active',
		property: 'border-color',
		value: '089BBE',
		isColorTile: true,
	},
];

export const sliderStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Label',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '13px',
		isColorTile: false,
	},
	{
		name: 'Helper Label',
		property: 'font-size',
		value: '10px',
		isColorTile: false,
	},
	{
		name: 'Helper Label',
		property: 'line-height',
		value: '11px',
		isColorTile: false,
	},
];

export const sliderStylesStructureOld = [
	{
		name: 'Slider',
		property: 'min-width',
		value: '240px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '240px',
		isColorTile: false,
	},
	{
		name: 'Numeric Input',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-width',
		value: '48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
];
