// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: applicationHeaderStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const applicationHeaderStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const applicationHeaderStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const applicationHeaderStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const applicationHeaderStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution
export const applicationHeaderStylesDefaultThemeOld = [
	{
		name: 'Background',
		property: 'background-color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Right Header Group Icons',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Right Header Group Text',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Dashed Border: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Background: focus/active',
		property: 'background-color',
		value: '066C84',
		isColorTile: true,
	},
	{
		name: 'Text: focus/active',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
];

export const applicationHeaderStylesDarkThemeOld = [
	{
		name: 'Background',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Right Header Group Icons',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Right Header Group Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Dashed Border: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Background: focus/active',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text: focus/active',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true,
	},
];

export const applicationHeaderStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Header Text',
		property: 'font-size',
		value: '24px',
		isColorTile: false,
	},
	{
		name: 'Left Header Group Text',
		property: 'font-weight',
		value: '700',
		isColorTile: false,
	},
];

export const applicationHeaderStylesStructureOld = [];
