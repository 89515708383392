// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: fileUploadStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const fileUploadStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const fileUploadStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const fileUploadStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const fileUploadStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const fileUploadStylesDefaultThemeOld = [
	{
		name: 'Label Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text (Internal)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text (Selected File)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text (Instructional)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text (Error Message)',
		property: 'color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon (Remove Selected File)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'F3F4F4',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Container (Error)',
		property: 'border-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Icon (Remove Selected File): hover',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon (Remove Selected File): focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const fileUploadStylesDarkThemeOld = [
	{
		name: 'Label Text',
		property: 'color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Text (Internal)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text (Selected File)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text (Instructional)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text (Error Message)',
		property: 'color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon (Remove Selected File)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Container (Error)',
		property: 'border-color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Icon (Remove Selected File): hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Icon (Remove Selected File): focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const fileUploadStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: 'Error Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
	{
		name: 'File Requirements Text',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
	{
		name: 'Helper Text',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
	{
		name: 'Upload Instruction Text',
		property: 'font-style',
		value: 'italic',
		isColorTile: false,
	},
];

export const fileUploadStylesStructureOld = [
	{
		name: 'File Upload Drop Zone',
		property: 'width',
		value: '323px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '211px',
		isColorTile: false,
	},
	{
		name: 'File Upload Drop Zone Wrapper',
		property: 'height',
		value: '35px',
		isColorTile: false,
	},
];
