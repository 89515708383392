// External Imports
import { Component, OnInit, Input } from '@angular/core';
import { ClipboardItem } from 'src/app/interfaces/clipboard-item';
import { ClipboardService } from '../../services/clipboard.service';

@Component({
	selector: 'app-clipboard',
	templateUrl: './clipboard.component.html',
	styleUrls: ['./clipboard.component.less'],
})
export class ClipboardComponent implements OnInit {
	@Input() data?: ClipboardItem;
	constructor(public clipboardService: ClipboardService) {}

	ngOnInit(): void {}
}
