import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatCrumbs' })

export class FormatCrumbsPipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		return JSON.stringify(value, null, 2)
			.replace(/\//g, ' > ')
			.replace(/^\"/, '')
			.replace(/\"$/, '');
	}
}
