import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import * as ShieldIcons from '@beyondtrust/shield-icons';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';

// Data Sources
import {
	textFieldStylesDefaultTheme,
	textFieldStylesDarkTheme,
	textFieldStylesTypography,
	textFieldStylesStructure,
} from './text-field-styles';
import { ChangelogService } from 'src/app/services/changelog.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-text-field',
	templateUrl: './text-field.component.html',
	styleUrls: ['./text-field.component.less'],
})
export class TextFieldComponent {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public simpleTextboxValue: string = 'Simple textbox value';
	public basicTextareaValue: string = 'Hello there!';
	public fetchingDomains: boolean = false;
	public fetchDomainsResult: string = '';
	public checkIcon = ShieldIcons.shCheck;
	public linkIcon = ShieldIcons.shLink;
	public searchIcon = ShieldIcons.shMagnifyingGlass;
	public xMarkIcon = ShieldIcons.shXmark;

	// Imported Style Variables
	public stylesDefault = textFieldStylesDefaultTheme;
	public stylesDark = textFieldStylesDarkTheme;
	public stylesTypography = textFieldStylesTypography;
	public stylesStructure = textFieldStylesStructure;

	public form: FormGroup = new FormGroup({
		firstName: new FormControl(),
	});
	public textareaValue: string = '';

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				// {
				// 	name: 'API',
				// 	value: 'api',
				// },
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Clearing text',
					value: 'clear',
				},
				{
					name: 'Error',
					value: 'error',
				},
				{
					name: 'Disabled',
					value: 'disabled',
				},
				{
					name: 'Help text',
					value: 'help-text',
				},
				{
					name: 'Instructional text',
					value: 'instructional-text',
				},
				{
					name: 'Character limits',
					value: 'character-limit',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Default',
					value: 'default',
				},
				{
					name: 'Multi-line',
					value: 'multiple',
				},
				{
					name: 'Optional and required',
					value: 'optional-required',
				},
				{
					name: 'Typeahead',
					value: 'typeahead',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Minimum and maximum width',
					value: 'min-max-width',
				},
				{
					name: 'Content',
					value: 'content',
				},
			],
		},
		{
			name: 'Resources',
			value: 'resources',
			empty: false,
			children: [],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	public defaultTextFieldHTML: string = `
<form
	class="k-form"
	[formGroup]="form"
>
	<kendo-formfield>
		<kendo-label
			[for]="firstName"
			text="First Name"
		></kendo-label>
		<kendo-textbox
			[maxlength]="300"
			formControlName="firstName"
			#firstName
			required
			[style.width.px]="400"
		>
			<ng-template kendoTextBoxSuffixTemplate>
				<button
					kendoTooltip
					*ngIf="firstName.value && firstName.value.length > 0"
					kendoButton
					title="Clear"
					class="suffix-clear-button"
					fillMode="clear"
					[svgIcon]="xMarkIcon"
					(click)="firstName.clearValue();"
				></button>
			</ng-template>
		</kendo-textbox>

		<kendo-formhint>Your first name</kendo-formhint>
		<kendo-formerror>First name is required</kendo-formerror>
	</kendo-formfield>
</form>`;

	public multiLineTextFieldHTML: string = `
<div
	class="k-form"
>
	<kendo-label
		class="k-form-field"
		text="Description"
	>
		<kendo-textarea
			#textArea
			[maxlength]="300"
			[style.width.px]="400"
			[rows]="3"
			resizable="none"
			[(value)]="textareaValue"
		>
			<kendo-textarea-suffix>
				<button
					kendoTooltip
					*ngIf="textareaValue.length > 0"
					kendoButton
					title="Clear"
					class="suffix-clear-button"
					fillMode="clear"
					[svgIcon]="xMarkIcon"
					(click)="textareaValue = '';textArea.blur();"
				></button>
			</kendo-textarea-suffix>
		</kendo-textarea>
	</kendo-label>
</div>
`;

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public fetchDomains(): void {
		this.fetchingDomains = true;
		this.somePromise()
			.then(() => {
				this.fetchDomainsResult = 'Domain 1, Domain 2, Domain 99';
			})
			.catch(() => {
				this.fetchDomainsResult = 'No Domains For You!';
			})
			.finally(() => {
				this.fetchingDomains = false;
			});
	}

	// randomly resolves / rejects
	public somePromise = async (): Promise<void> => {
		return new Promise((resolve, reject) => {
			window.setTimeout(() => {
				const result: boolean = Math.random() > 0.5;
				if (result) {
					resolve();
				} else {
					reject();
				}
			}, 2000);
		});
	};
}
