// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const buttonGroupStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Icon (selected)',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Border (selected)',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Border: hover',
		property: 'border-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	}
];

export const buttonGroupStylesDefaultThemeIcon = [
	{
		name: 'Icon (non-logo)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	}
];

export const buttonGroupStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: 'Icon (selected)',
		property: 'color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: 'Border (selected)',
		property: 'border-color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Icon: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Border: hover',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const buttonGroupStylesDarkThemeIcon = [
	{
		name: 'Icon (non-logo)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	}
];

export const buttonGroupStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];

export const buttonGroupStylesStructure = [
	{
		name: 'Container',
		property: 'min-width',
		value: '90px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: 'unset',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '36px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Border',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: 'Border-bottom (selected)',
		property: 'border-width',
		value: '3px',
		isColorTile: false,
	},
	{
		name: 'Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];
export const buttonGroupStylesStructureIcon = [
	{
		name: 'Icon',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
];
