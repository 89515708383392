import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import {
	radioButtonStylesDefaultTheme,
	radioButtonStylesDarkTheme,
	radioButtonStylesTypography,
	radioButtonStylesStructure,
} from './radio-button-styles';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { shCheck, shXmark } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-radio-button',
	templateUrl: './radio-button.component.html',
	styleUrls: ['./radio-button.component.less'],
})
export class RadioButtonComponent {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'Overview';
	public doThisIcon = shCheck;
	public notThisIcon = shXmark;

	// Imported Style Variables
	public stylesDefault = radioButtonStylesDefaultTheme;
	public stylesDark = radioButtonStylesDarkTheme;
	public stylesTypography = radioButtonStylesTypography;
	public stylesStructure = radioButtonStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Disable',
					value: 'disabled',
				},
				{
					name: 'Instructional text',
					value: 'instructional-text',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Position',
					value: 'position',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	public form: FormGroup;
	public data: { [Key: string]: string } = {
		confirmation: 'phoneCall'
	};

	public isDisabled = false;

	public radioHTML = `
<form class="k-form" [formGroup]="form">
	<kendo-formfield showHints="initial">
		<label class="k-label">Type of Confirmation</label>
		<ul class="k-radio-list">
			<li class="k-radio-item">
				<div class="k-radio-block">
					<input type="radio" #phoneCall value="phoneCall" kendoRadioButton
						formControlName="confirmation" />
					<kendo-label [for]="phoneCall" text="Phone Call"></kendo-label>
				</div>
			</li>
			<li class="k-radio-item">
				<div class="k-radio-block">
					<input type="radio" #email kendoRadioButton value="email"
						formControlName="confirmation" />
					<kendo-label [for]="email" text="Via Email"></kendo-label>
				</div>
				<kendo-formhint class="k-radio-formhint" [class.disabled]="isDisabled">Radio button instructional text</kendo-formhint>
			</li>
			<li class="k-radio-item">
				<div class="k-radio-block">
					<input type="radio" disabled name="foo" value="one" #disabledAttribute
						kendoRadioButton />
					<kendo-label [for]="disabledAttribute"
						text="Radio button disabled with the disabled attribute"></kendo-label>
				</div>
			</li>
		</ul>
		<kendo-formhint>Hint: Choose a way to receive a confirmation</kendo-formhint>
	</kendo-formfield>
</form>`;

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
		this.form = new FormGroup({
			confirmation: new FormControl(this.data.confirmation, [
				Validators.required,
			]),
		});
	}

	public submitForm(): void {
		this.form.markAllAsTouched();
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
