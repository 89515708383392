// External Imports
import { Injectable, InjectionToken } from '@angular/core';
import { DeepCopy } from '../utilities/deep-copy';

// Internal Imports
import { BreadCrumbItem } from '../interfaces/breadcrumb';

@Injectable({
	providedIn: 'root',
})
export class BreadCrumbService {
	
	public breadcrumbArray: BreadCrumbItem[] = [];

	constructor() {
		this.breadcrumbArray.push(
			{
				id: 0,
				label: 'Home',
				url: '/',
			},
		);
	}

	public getBreadCrumbs(): BreadCrumbItem[] {
		return this.breadcrumbArray;
	}

	public addBreadCrumb(newBreadCrumbItem: BreadCrumbItem): void {
		DeepCopy(this.breadcrumbArray.push(newBreadCrumbItem));
	}

	public resetBreadCrumb(): void {
		DeepCopy(this.breadcrumbArray.splice(1, 1));
	}
}
