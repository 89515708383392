import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import * as ShieldIcons from '@beyondtrust/shield-icons';

// Data Sources
import {
	accordionStylesDefaultTheme,
	accordionStylesDarkTheme,
	accordionStylesTypography,
	accordionStylesStructure,
} from './accordion-styles';

@Component({
	selector: 'app-accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.less'],
})
export class AccordionComponent {
	public currLive = 'single';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefault = accordionStylesDefaultTheme;
	public stylesDark = accordionStylesDarkTheme;
	public stylesTypography = accordionStylesTypography;
	public stylesStructure = accordionStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Hover',
					value: 'hover',
				},
				{
					name: 'Disable',
					value: 'disable',
				},
				{
					name: 'Animation',
					value: 'animation',
				},
				{
					name: 'Content toggle',
					value: 'content',
				},
				{
					name: 'Collapse',
					value: 'collapse',
				},
				{
					name: 'Expand',
					value: 'expand',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	public duration = 250;
	public up = ShieldIcons.shChevronUp;
	public down = ShieldIcons.shChevronDown;
  
	public step_1_title = 'Step 1';
	public step_2_title = 'Step 2';
	public step_3_title = 'Step 3';
	public step_5_title = 'Okta API Scopes';

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public accordionHTML = `
<kendo-expansionpanel
	[title]="step_5_title"
	[expanded]="true"
	[disabled]="false"
	[animation]="duration"
	[svgExpandIcon]="down"
	[svgCollapseIcon]="up"
	>
	<div class="content">
		<div class="form-item">
			<div class="title">Go to <b>Okta API Scopes</b>. Grant access to</div>
			<ul>
				<li>okta.apps.read</li>
				<li>okta.groups.read</li>
				<li>okta.idps.read</li>
				<li>okta.logs.read</li>
				<li>okta.policies.read</li>
				<li>okta.roles.read</li>
				<li>okta.users.read</li>
			</ul>
		</div>
	</div>
</kendo-expansionpanel>`;

	public accordionTS = `
@ViewChildren(ExpansionPanelComponent)
panels: QueryList<ExpansionPanelComponent>;

public duration = 250;
public up = ShieldIcons.shChevronUp;
public down = ShieldIcons.shChevronDown;

public step_1_title = "Step 1";
public step_2_title = "Step 2";
public step_3_title = "Step 3";
public step_4_title = "Step 4";
public step_5_title = "Okta API Scopes";
public step_6_title = "Step 6";

public onAction(index: number): void {
	this.panels.forEach((panel, idx) => {
	if (idx !== index && panel.expanded) {
		panel.toggle();
	}
	});
}`;

}
