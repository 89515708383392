// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: progressIndicatorStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const progressIndicatorStylesDefaultTheme = [
	{
		name: 'Container',
		property: 'background-color',
		value: 'FF5500',
		isColorTile: true,
	},
];
export const progressIndicatorStylesDarkTheme = [
	{
		name: 'Container',
		property: 'background-color',
		value: 'FF8040',
		isColorTile: true,
	},
];
export const progressIndicatorStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const progressIndicatorStylesLineStructure = [
	{
		name: 'Container',
		property: 'height',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
];
export const progressIndicatorStylesCircleStructure = [
	{
		name: 'Container',
		property: 'line-width',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px or 48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '24px or 48px',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const progressIndicatorStylesDefaultThemeOld = [
	{
		name: 'Indicator',
		property: 'background-color',
		value: 'FFD4BF',
		isColorTile: true,
	},
];

export const progressIndicatorStylesDefaultThemeLine = [
	{
		name: 'Container',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
];

export const progressIndicatorStylesDefaultThemeLineRepeat = [
	{
		name: 'Container',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
];

export const progressIndicatorStylesDarkThemeOld = [
	{
		name: 'Indicator',
		property: 'background-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const progressIndicatorStylesDarkThemeLine = [
	{
		name: 'Container',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
];

export const progressIndicatorStylesDarkThemeLineRepeat = [
	{
		name: 'Container',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
];

export const progressIndicatorStylesCircleTypography = [
	{
		name: 'Label and transitional text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const progressIndicatorStylesStructureLine = [
	{
		name: 'Container',
		property: 'height',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
];

export const progressIndicatorStylesStructureLineRepeat = [
	{
		name: 'Container',
		property: 'height',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
];

export const progressIndicatorStylesStructureCircle = [
	{
		name: 'Container',
		property: 'height',
		value: '48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '48px',
		isColorTile: false,
	},
];
