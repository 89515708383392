// External Imports
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadCrumbService } from '../../services/breadcrumbs.service';

// Internal Imports
@Component({
	selector: 'app-not-found',
	templateUrl: './notfound.component.html',
	styleUrls: ['./notfound.component.less'],
})

// Temporary component - shouldn't necessarily be needed and is more a proof
// of concept for redirecting someone out of where they shouldn't be
export class NotFoundComponent implements OnInit {
	constructor(
		private titleService: Title,
		private breadcrumbs: BreadCrumbService
	) {}

	private breadcrumbsArray = [
		{
			id: 2,
			label: 'Nothing Here',
			url: '/',
		},
	];

	ngOnInit() {
		this.titleService.setTitle('Nothing Here');
		this.manageBreadcrumbs();
	}

	private manageBreadcrumbs() {
		setTimeout(() => {
			this.breadcrumbs.resetBreadCrumb();
			for (let index = 0; index < this.breadcrumbsArray.length; index++) {
				const element = this.breadcrumbsArray[index];
				this.breadcrumbs.addBreadCrumb(element);
			}
		}, 0);
	}
}
