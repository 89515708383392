// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonLinkStylesDarkThemePrimary

export const textLinkStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Text (visited)',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Text (visited): hover',
		property: 'color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
];

export const textLinkStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '95DBEC',
		isColorTile: true,
	},
	{
		name: 'Text (visited)',
		property: 'color',
		value: '95DBEC',
		isColorTile: true,
	},
	{
		name: 'Text: hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Text (visited): hover',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const textLinkStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'text-decoration',
		value: 'underline',
		isColorTile: false,
	},
];

export const textLinkStylesStructure = [
	{
		name: 'Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'Link with Icon',
		property: 'gap',
		value: '4px',
		isColorTile: false,
	},
];
