// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: notificationStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const notificationStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const notificationStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const notificationStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const notificationStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const notificationStylesDefaultThemeOld = [
	{
		name: 'Border',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: false,
	},
];

export const notificationStylesDarkThemeOld = [
	{
		name: 'Border',
		property: 'border-color',
		value: '425563',
		isColorTile: false,
	},
];

export const notificationStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
];

export const notificationStylesStructureOld = [
	{
		name: 'notification',
		property: 'max-width',
		value: '620px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Toggle Icon',
		property: 'top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'right',
		value: '2px',
		isColorTile: false,
	},
	{
		name: 'notification Top',
		property: 'padding-top',
		value: '20px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'notification Content',
		property: 'height',
		value: '100%',
		isColorTile: false,
	},
];
