import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { colorTileData } from './color-tile-data';
import { ClipboardService } from 'src/app/services/clipboard.service';

@Component({
	selector: 'app-color-tile',
	templateUrl: './color-tile.component.html',
	styleUrls: ['./color-tile.component.less'],
})
export class ColorTileComponent implements OnInit {
	@Input() hexValue: string;

	public colorDropDownShown = false;
	public data = colorTileData;
	public colorData;

	constructor(private clipboardService: ClipboardService) {}

	ngOnInit(): void {
		this.colorData = this.data.find((x) => x.hex === '#' + this.hexValue);
	}

	public copyToClipboard(colorValue): void {
		this.clipboardService.createClipboard({
			value: colorValue,
			type: 'color',
		});
		this.colorDropDownShown = false;
	}

	public onClickedOutside(event: any): void {
		this.colorDropDownShown = false;
	}

	public onTileClicked(): void {
		this.colorDropDownShown = !this.colorDropDownShown;
	}
}
