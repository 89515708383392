// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const tagStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '7C8790',
		isColorTile: true,
	},
];

export const tagStylesDefaultThemeLowSeverity = [
	{
		name: 'Text',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '3992C6',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '3992C6',
		isColorTile: true,
	},
];

export const tagStylesDefaultThemeModerateSeverity = [
	{
		name: 'Text',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'C6B539',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'C6B539',
		isColorTile: true,
	},
];

export const tagStylesDefaultThemeHighSeverity = [
	{
		name: 'Text',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'C67839',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'C67839',
		isColorTile: true,
	},
];

export const tagStylesDefaultThemeCriticalSeverity = [
	{
		name: 'Text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '783A3B',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '783A3B',
		isColorTile: true,
	},
];

export const tagStylesDefaultThemeFilter = [
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '7C8790',
		isColorTile: true,
	},
];

export const tagStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
];

export const tagStylesDarkThemeLowSeverity = [
	{
		name: 'Text',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '71CFE6',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '71CFE6',
		isColorTile: true,
	},
];

export const tagStylesDarkThemeModerateSeverity = [
	{
		name: 'Text',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'F3C781',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'F3C781',
		isColorTile: true,
	},
];

export const tagStylesDarkThemeHighSeverity = [
	{
		name: 'Text',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'F39681',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'F39681',
		isColorTile: true,
	},
];

export const tagStylesDarkThemeCriticalSeverity = [
	{
		name: 'Text',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'D6B9FF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'D6B9FF',
		isColorTile: true,
	},
];

export const tagStylesDarkThemeFilter = [
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
];

export const tagStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];

export const tagStylesTypographyFilter = [
	{
		name: 'Text (Label)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'Text (Value)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const tagStylesStructure = [
	{
		name: 'Container',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '4px',
		isColorTile: false,
	},
];

export const tagStylesStructureFilter = [
	{
		name: 'Container',
		property: 'height',
		value: '34px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '200px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-radius',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Text (All)',
		property: 'padding-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Text (Label)',
		property: 'margin-right',
		value: '4px',
		isColorTile: false,
	},
	{
		name: 'Icon',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: 'Icon (Remove)',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
];
