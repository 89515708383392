import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { PageSubNavItem } from './page-sub-nav.interface.';

@Component({
	selector: 'app-page-sub-nav',
	templateUrl: './page-sub-nav.component.html',
	styleUrls: ['./page-sub-nav.component.less'],
})
export class PageSubNavComponent implements OnInit {
	@Input() data: PageSubNavItem[];
	@Input() currVisible: string;

	constructor(private viewportScroller: ViewportScroller) {}

	ngOnInit(): void {}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}
}
