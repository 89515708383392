<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Padding is the space between elements on a UI. Spacing is the
				space between two containers in a layout. Together, padding and
				spacing help create clear, functional layouts.
			</li>
		</ul>
	</section>
	<section class="page-section" id="principles">
		<h2 id="sectionTitlePrinciples" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('principles')">
			Principles<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Create
					relationships and hierarchy.</span>
				Elements near each other are seen as being related. When more
				space is added between elements, their perceived relationship
				weakens. Elements with more spacing around them are usually seen
				as more important than elements that have less space around
				them.
			</li>
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Variance, but
					also consistency.</span>
				Padding and spacing varies by interface, element, and
				relationship. For example, the spacing between two form fields
				is different than the spacing between a form field and a section
				title. There is no way to account for all the potential
				combinations, but following these guidelines ensures clarity and
				functionality across our products.
			</li>
		</ul>
	</section>
	<section class="page-section" id="basics-and-tools">
		<h2 id="sectionTitleBasicsAndTools" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('basics-and-tools')">
			Basics & tools<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<section class="page-sub-section" id="basics-and-tools-4-px-grid">
			<h3 id="subSectionTitleBasicsAndTools4PxGrid" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('basics-and-tools-4-px-grid')">
				4px grid<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					We use a base grid of 4px. Sometimes spacing inside a
					component needs to be a different number to make the overall
					component hit a desired size, but the spacing between items
					should nearly always be in increments of 4. Half spacing
					(2px) is used, but rarely.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="basics-and-tools-figma-redlines">
			<h3 id="subSectionTitleBasicsAndToolsFigmaRedlines" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('basics-and-tools-figma-redlines')">
				Figma redlines<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Redlines are the actual guides in Figma designs that
					communicate the exact padding and spacing specs.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="common-spacing-rules">
		<h2 id="sectionTitleCommonSpacingRules" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('common-spacing-rules')">
			Common spacing rules<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<section class="page-sub-section" id="common-spacing-rules-titles">
			<h3 id="subSectionTitleCommonSpacingRulesTitles" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('common-spacing-rules-titles')">
				Titles<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Titles are 20px from the top of a container.
						</li>
						<li class="page-section-list-item">
							Content is typically 16px from both sides of its container.
							A notable exception is grids, which fill their container.
						</li>
						<li class="page-section-list-item">Panels have at least 72px space between the title and the
							right icon, like the closing chevron or X.
						</li>
						<li class="page-section-list-item">Titles for an entire page or panel have 16px below them, but
							these are not to be confused with section headings.</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-section-headings">
			<h3 id="subSectionTitleCommonSpacingRulesSectionHeadings" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('common-spacing-rules-section-headings')">
				Section headings<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Section headings are 24px below the previous sections they
							follow.
						</li>
						<li class="page-section-list-item">
							Content under a section heading starts 8px below it.
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-button-spacing">
			<h3 id="subSectionTitleCommonSpacingRulesButtonSpacing" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('common-spacing-rules-button-spacing')">
				Button spacing<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							If a form has sections with buttons, the buttons are 16px below the last field in the
							section.
						</li>
						<li class="page-section-list-item">
							Buttons at the bottom of forms that don’t move, known as
							docked buttons, have a top and bottom spacing of 8px.
						</li>
						<li class="page-section-list-item">Buttons on modals or on panels that don’t belong to a form
							have 16px space between the bottom of the content and the
							buttons.</li>
						<li class="page-section-list-item">Buttons have 8px spacing between other buttons.</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container" style="width:483px; height: 240px; ">
						<iframe style="width:519px; height:370px;" class="redlines"
							src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeAoVJlAbfpXNs3PTSX0XIt%2FRedlines-(Guidance)%3Ftype%3Ddesign%26node-id%3D3572%253A60%26mode%3Ddesign%26t%3DRIHutEk647iiNzff-1"></iframe>
					</div>
					<span class="redlines-caption">Section buttons have 16px above them.</span>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container" style="width:483px; height: 400px; ">
						<iframe style="width:580px; height:500px;" class="redlines"
							src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeAoVJlAbfpXNs3PTSX0XIt%2FRedlines-(Guidance)%3Ftype%3Ddesign%26node-id%3D3573%253A391%26mode%3Ddesign%26t%3DqPyIvehiBV8LgN9L-1"></iframe>
					</div>
					<span class="redlines-caption">Docked buttons have 8px above
						and below them.</span>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container" style="width: 483px; height: 340px; ">
						<iframe style="width:560px; height: 440px;" class="redlines"
							src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeAoVJlAbfpXNs3PTSX0XIt%2FRedlines-(Guidance)%3Ftype%3Ddesign%26node-id%3D3573%253A2393%26mode%3Ddesign%26t%3DqPyIvehiBV8LgN9L-1"></iframe>
					</div>
					<span class="redlines-caption">Panel buttons have 24px above
						them and 8px between them.</span>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-text-fields-spacing">
			<h3 id="subSectionTitleCommonSpacingRulesTextFieldsSpacing" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('common-spacing-rules-text-fields-spacing')">
				Text fields spacing<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Use 16px space between text fields.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-other-component-spacing">
			<h3 id="subSectionTitleCommonSpacingRulesOtherComponentSpacing" class="page-sub-section-title"
				title="Copy Link to Clipboard"
				(click)="copyToClipboard('common-spacing-rules-other-component-spacing')">
				Other component spacing<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Switches</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							16px between switches
						</li>
					</ul>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Checkboxes</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							16px for single checkbox item
						</li>
						<li class="page-section-list-item">
							0px between multiple checkbox items
						</li>
					</ul>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Combo box and drop-downs</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Minimum height of 48px for options in a drop-down or combo
							box
						</li>
						<li class="page-section-list-item">
							8px above and below the options in the list
						</li>
					</ul>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Radio buttons</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							0px between typical radio buttons with no description
						</li>
						<li class="page-section-list-item">
							8px between descriptive radio button items in a group
						</li>
						<li class="page-section-list-item">
							16px between inline radio buttons in a group
						</li>
						<li class="page-section-list-item">
							16 points between radio buttons with description and a
							related or nested field
						</li>
					</ul>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container" style="width:483px; height: 520px; ">
						<iframe style="width:540px; height: 590px;" class="redlines"
							src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeAoVJlAbfpXNs3PTSX0XIt%2FRedlines-(Guidance)%3Ftype%3Ddesign%26node-id%3D3630%253A16090%26mode%3Ddesign%26t%3Drr50GILQtCYPOLlb-1"></iframe>
					</div>
					<span class="redlines-caption">0px between typical radio
						button items.</span>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container" style="width: 483px; height: 430px; ">
						<iframe style="width:540px; height: 490px;" class="redlines"
							src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeAoVJlAbfpXNs3PTSX0XIt%2FRedlines-(Guidance)%3Ftype%3Ddesign%26node-id%3D3683%253A1329%26mode%3Ddesign%26t%3Drr50GILQtCYPOLlb-1"></iframe>
					</div>
					<span class="redlines-caption">8px between radio buttons
						with instructional text and 16px between related or
						nested fields.</span>
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container" style="width:483px; height: 260px; ">
						<iframe style="width:500px; height: 390px;" class="redlines"
							src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeAoVJlAbfpXNs3PTSX0XIt%2FRedlines-(Guidance)%3Ftype%3Ddesign%26node-id%3D3630%253A17531%26mode%3Ddesign%26t%3Drr50GILQtCYPOLlb-1"></iframe>
					</div>
					<span class="redlines-caption">16px between inline radio
						buttons.</span>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-panel-spacing">
			<h3 id="subSectionTitleCommonSpacingRulesPanelSpacing" class="page-sub-section-title"
				title="Copy Link to Clipboard" (click)="copyToClipboard('common-spacing-rules-panel-spacing')">
				Panel spacing<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Panels or cards have 8px spacing between them.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="common-spacing-rules-page-application-spacing">
			<h3 id="subSectionTitleCommonSpacingRulesPageApplicationSpacing" class="page-sub-section-title"
				title="Copy Link to Clipboard"
				(click)="copyToClipboard('common-spacing-rules-page-application-spacing')">
				Page Application Spacing <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Pages have 24px border around them before content begins —
					unless there’s a fixed left menu “stuck” on the left of the
					browser. If so, the content starts 24px to the right of the
					menu, as though there was still a 24px buffer on the entire
					page.
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container" style="width:580px; height: 360px; ">
						<iframe style="width:638px; height: 450px;" class="redlines"
							src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FeAoVJlAbfpXNs3PTSX0XIt%2FRedlines-(Guidance)%3Ftype%3Ddesign%26node-id%3D3649%253A19222%26mode%3Ddesign%26t%3DA9eUCr1UK8K91Es6-1"></iframe>
					</div>
					<span class="redlines-caption">Spacing around page content.</span>
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/radio-button">Radio button component</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Spacing Guidelines'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>