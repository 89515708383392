import { Component, OnInit, ViewChild } from '@angular/core';
import { searchData } from './search-data';
import { Router } from '@angular/router';
import { shMagnifyingGlass } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.less'],
})
export class SearchComponent implements OnInit {
	@ViewChild('criteria', { static: false }) criteria: any;
	@ViewChild('SearchInput', { static: false }) SearchInput: any;

	public inputDropDownShown = false;
	public data = searchData;
	public inputValue = '';
	public seachResults: any[] = [];
	public searchIcon = shMagnifyingGlass;

	constructor(private router: Router) {
		// this.data.sort((a, b) =>
		// 	a.name > b.name ? 1 : b.name > a.name ? -1 : 0
		// );
	}

	ngOnInit(): void {}

	public onSearchInput(): void {
		this.inputDropDownShown = true;
		let criteria = this.SearchInput.nativeElement.value;

		if (criteria.length > 2) {
			this.seachResults = this.data.filter((searchFilter) =>
				searchFilter.meta
					.toLocaleLowerCase()
					.includes(criteria.toLocaleLowerCase())
			);
		} else {
			this.seachResults = [];
		}

		if (criteria.length === 0) {
			this.inputDropDownShown = false;
		}
	}

	public redirectTo(uri: any) {
		this.inputDropDownShown = false;
		this.SearchInput.nativeElement.value = '';
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public onClickedOutside(event: any): void {
		this.inputDropDownShown = false;
		this.SearchInput.nativeElement.value = '';
	}
}
