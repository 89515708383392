// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: treeBrowserStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const treeBrowserStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const treeBrowserStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const treeBrowserStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const treeBrowserStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const treeBrowserStylesDefaultThemeOld = [];

export const treeBrowserStylesDarkThemeOld = [];

export const treeBrowserStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Breadcrumb Text',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
];

export const treeBrowserStylesStructureOld = [
	{
		name: 'Container',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '100%',
		isColorTile: false,
	},
	{
		name: 'Breadcrumb',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-bottom',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'Parent/Child Items',
		property: 'min-height',
		value: '48px',
		isColorTile: false,
	},
	{
		name: 'Parent/Child Icons',
		property: 'width',
		value: '20px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '20px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '12px',
		isColorTile: false,
	},
];
