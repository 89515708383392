import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChangelogService } from 'src/app/services/changelog.service';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';

@Component({
	selector: 'app-spacing',
	templateUrl: './spacing.component.html',
	styleUrls: ['./spacing.component.less'],
})
export class SpacingComponent {
	public currVisible = 'overview';

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Principles',
			value: 'principles',
			empty: false,
			children: [],
		},
		{
			name: 'Basics & tools',
			value: 'basics-and-tools',
			empty: false,
			children: [
				{
					name: '4px grid',
					value: '4-px-grid',
					empty: false,
					children: [],
				},
				{
					name: 'Figma redlines',
					value: 'figma-redlines',
					empty: false,
					children: [],
				},
			],
		},
		{
			name: 'Common spacing rules',
			value: 'common-spacing-rules',
			empty: false,
			children: [
				{
					name: 'Titles',
					value: 'titles',
					empty: false,
					children: [],
				},
				{
					name: 'Section headings',
					value: 'section-headings',
					empty: false,
					children: [],
				},
				{
					name: 'Button spacing',
					value: 'button-spacing',
					empty: false,
					children: [],
				},
				{
					name: 'Text fields spacing',
					value: 'text-fields-spacing',
					empty: false,
					children: [],
				},
				{
					name: 'Other component spacing',
					value: 'other-component-spacing',
					empty: false,
					children: [],
				},
				{
					name: 'Panel spacing',
					value: 'panel-spacing',
					empty: false,
					children: [],
				},
				{
					name: 'Page application spacing',
					value: 'page-application-spacing',
					empty: false,
					children: [],
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
