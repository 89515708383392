// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: loginStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const loginStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const loginStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const loginStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const loginStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const loginStylesDefaultThemeOld = [];

export const loginStylesDarkThemeOld = [];

export const loginStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Input Text',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: 'Label Text',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
	{
		name: 'Header Text (H1)',
		property: 'font-size',
		value: '20px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
];

export const loginStylesStructureOld = [
	{
		name: 'BeyondTrust Logo',
		property: 'margin-bottom',
		value: '48px',
		isColorTile: false,
	},
	{
		name: 'Header Text (H1)',
		property: 'margin-bottom',
		value: '24px',
		isColorTile: false,
	},
	{
		name: 'Forgot Password Link',
		property: 'margin-top',
		value: '5px',
		isColorTile: false,
	},
	{
		name: 'Login Form Container',
		property: 'margin-bottom',
		value: '16px',
		isColorTile: false,
	},
	{
		name: 'Footer',
		property: 'margin-top',
		value: '48px',
		isColorTile: false,
	},
];
