// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: listStylesStructureWide or buttonStylesDarkThemePrimary

//Placeholder sections - replace with actual styles
export const listStylesDefaultTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const listStylesDarkTheme = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'FFFFFF',
		isColorTile: true,
	},
];
export const listStylesTypography = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];
export const listStylesStructure = [
	{
		name: 'Section name placeholder',
		property: 'css style name placeholder',
		value: 'css value placeholder',
		isColorTile: false,
	},
];

//The styles below are old and either out of date or not confirmed corrrect, use them with caution

export const listStylesDefaultThemeOld = [
	{
		name: 'Header Background',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Header Text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Row Background',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Row Background (Selected)',
		property: 'background-color',
		value: 'D3F0F7',
		isColorTile: true,
	},
	{
		name: 'Row Text / Icons',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Row Text / Icons (Selected)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: 'FFE6D9',
		isColorTile: true,
	},
	{
		name: 'Text Link (Separate from row hover)',
		property: 'color',
		value: 'N/A',
		isColorTile: false,
	},
];

export const listStylesDarkThemeOld = [
	{
		name: 'Header Background',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Header Text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Row Background',
		property: 'background-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Row Background (Selected)',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Row Text / Icons',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Row Text / Icons (Selected)',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text Link (Separate from row hover)',
		property: 'color',
		value: 'FF8040',
		isColorTile: true,
	},
];

export const listStylesTypographyOld = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Item Count Text',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
	{
		name: 'Row Text',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
];

export const listStylesStructureOld = [
	{
		name: 'Container',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
	{
		name: 'Item Count Header',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	{
		name: 'Grid Body',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: 'Grid Row',
		property: 'height',
		value: '112px',
		isColorTile: false,
	},
];
