import {
	Injectable,
	EventEmitter,
	OnDestroy,
	Renderer2,
	RendererFactory2,
} from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class WindowScrollService implements OnDestroy {
	private renderer2: Renderer2;
	public currentSection;
	public sectionChange = new EventEmitter<string>();
	public listener;

	constructor(
		rendererFactory: RendererFactory2,
		private router: Router
	) {
		this.renderer2 = rendererFactory.createRenderer(null, null);
		this.scrollWhenReady();
		this.listener = this.renderer2.listen('document', 'scroll', (e) => {
			const pageSections = this.getPageSections();
			//sort by closest to furthest from top, find first visible section
			for (let i = 0; i < pageSections.length; i++) {
				let section = pageSections[i];
				let height = section.getBoundingClientRect().height;
				let distanceFromTop = section.getBoundingClientRect().top;

				//we've reached a section that is visible
				if (height + distanceFromTop >= 0) {
					let firstVisibleSection = section.id;
					//section has subsections
					if (i + 1 in pageSections && pageSections[i + 1].classList.contains('page-sub-section')) {
						let j = i + 1; //next item will be subsection
						let subSection = pageSections[j];

						//find first visible subsection
						//loop until we've found a visible subsection or we run out of subsections
						while (firstVisibleSection === section.id && subSection.classList.contains('page-sub-section')) {
							let subSectHeight = subSection.getBoundingClientRect().height;
							let subSectFromTop = subSection.getBoundingClientRect().top;

							//we've found first visible subsection
							//or we've reached last subsection
							//(outer parent section can have margin or padding that is outside last subsection bounds)
							//subtract 60 to account for header
							if (subSectHeight + subSectFromTop - 60 >= 0) {
								firstVisibleSection = subSection.id;
							}
							subSection = pageSections[++j];

							//we've reached the next section, set the first visible section to
							//the last subsection
							if (firstVisibleSection === section.id && !subSection.classList.contains('page-sub-section')) {
								firstVisibleSection = pageSections[j - 1].id;
							}
						}
					}
					this.sectionChange.emit(firstVisibleSection);
					break;
				}
			}
		});
	}

	public getPageSections() {
		let pageSections = Array.from(
			document.getElementsByClassName('page-section')
		);
		let pageSubSections = Array.from(
			document.getElementsByClassName('page-sub-section')
		);

		//combine all page nav items into one array
		return pageSections.concat(pageSubSections).sort((a, b) => {
			//a is closer to top
			if (a.getBoundingClientRect().top < b.getBoundingClientRect().top)
				return -1;
			//b is closer to top
			else return 1;
		});
	}

	private async scrollWhenReady(): Promise<void> {
		try {
			await firstValueFrom(fromEvent(document, "readystatechange"));
			const fragment = this.router.url.split("#").pop();
			const destination = document.getElementById(fragment);
			const subsectionOffset = destination.classList.contains('page-sub-section') ? destination.parentElement.offsetTop : 0;
			// 75 = 60 to account for header + 15 to give it some space between the section title and the header
			const scrollTo = Math.abs(destination.offsetTop) + subsectionOffset - 75;
			document.documentElement.scrollTo(0, scrollTo);
		} catch (err) {
			console.log(`Error ${err}`);
		}
	}

	public ngOnDestroy(): void {
		this.listener();
	}
}
